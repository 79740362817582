import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import ReactPaginate from 'react-paginate';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {PATIENT_API, CREATE_PATIENT, EDIT_PATIENT ,UPDATE_PATIENT,DELETE_PATIENT } from '../../routes/api/Index';
import FilterPatient from '../../components/FilterPatient';
import Table from '../../components/Table';
export default function Index() {
  
  const token = localStorage.getItem('token');

  // 
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [email, setEmail] = useState('');
  const [referral, setReferral] = useState('');
  const [relationship, setRelationship] = useState('');
  const [cnic, setCnic] = useState('');
  const [whatsappno, setWhatsappno] = useState(''); 
  const [address,setAdress]=useState('');
  // 
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [id,setId]=useState('');

  const [data,setData]=useState([]);

  function resetValues() {
    setName('');
    setAge('');
    setCity('');
    setCountry('');
    setGender('');
    setPhoneno('');
    setEmail('');
    setReferral('');
    setCnic('');
    setWhatsappno('');
    setRelationship('');
    setAdress('');
  }


  useEffect(() => {
    fetchData();
  }, [pageIndex]);

  function assignvalues(name,age,city,country,phone_no,email,gender,referral,cnic,whatsapp_no,relationship,address){
      setName(name);
      setAge(age);
      setCity(city);
      setCountry(country);
      setGender(gender);
      setPhoneno(phone_no);
      setEmail(email);
      setReferral(referral);
      setCnic(cnic);
      setWhatsappno(whatsapp_no);
      setRelationship(relationship);
      setAdress(address);

  }
  

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1
    setPageIndex(selectedPage);
   
  };


  const handleSubmit = async () => {
    try {
      
      const requestBody = JSON.stringify({
      name,
      age,
      city,
      country,
      gender,
      phoneno,
      email,
      referral,
      cnic,
      whatsappno,
      relationship,
      address
      });
      
      const response = await fetch(CREATE_PATIENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }

      if (responseData.status === 200) {
        toast.success(responseData.message || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        fetchData();
       
        resetValues();
       
        let close=document.getElementById('close-btn');
        close.click();
      } else {
        toast.error(`${responseData.message}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }

      console.log('Form submitted successfully');
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit= async(id)=>{
   
    const response = await fetch(`${EDIT_PATIENT}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    let data=responseData.data;
    assignvalues(data.name,data.age,data.city,data.country,data.phone_no,data.email,data.gender,data.referral_id,data.cnic,data.whatsapp_no,data.relation_ship,data.address);
    if(responseData.status===200){
      setId(id);
      toast.success(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    
    } 
    else{
      toast.error(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    
    
  }


  const handleUpdate = async () => {
    if (loading) return; // Prevent multiple submissions
  
    setLoading(true);
    
    try {
      const requestBody = JSON.stringify({
        id,
        name,
        age,
        city,
        country,
        gender,
        phoneno,
        email,
        referral,
        cnic,
        whatsappno,
        relationship,
        address
      });
  
      const response = await fetch(UPDATE_PATIENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }
  
      if (responseData.status === 200) {
        toast.success(responseData.message || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
  
        fetchData();
        resetValues();
        
        let close = document.getElementById('close-btn-update');
        close.click();
      } else {
        toast.error(responseData.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
  
      console.log('Form submitted successfully');
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false); // Reset loading status
    }
  };


  const handleDelete = async () => {
    // Perform delete operation using the id
    try {

      const response = await fetch(`${DELETE_PATIENT}/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Network response was not ok');
      }
  
      // Handle successful deletion
      console.log('Item deleted successfully');
      toast.success(responseData.message || 'Item deleted successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      fetchData();
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }
  
  function handleId(id){
    setId(id);
  }
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${PATIENT_API}?page=${pageIndex}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    console.log(responseData.data);
    setData(responseData.data.data);
    setPageCount(responseData.last_page);
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setLoading(false);
  }

    }
  

    const columns = React.useMemo(
      () => [
          {
              Header: 'Reg_no',
              accessor: 'reg_no',
            
          },

          {
              Header: 'Name',
              accessor: 'name',
          },
          {
            Header: 'Age',
            accessor: 'age',
           },
           {
            Header: 'City',
            accessor: 'city',
           },{
            Header: 'cnic',
            accessor: 'cnic',
           },{
            Header: 'Gender',
            accessor: 'gender',
           },{
            Header: 'Phone no',
            accessor: 'phone_no',
           },{
            Header: 'Email',
            accessor: 'email',
           },{
            Header: 'Relationship',
            accessor: 'relationship',
           },
          {
              Header: <div className="text-center">Actions</div>,
              accessor: 'actions', // Unique identifier for the action column
              Cell: ({ row }) => (
                  <div className="d-flex justify-content-center">
                      <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="button-tooltip-3">Update Patient</Tooltip>}
                      >
                          <i onClick={() => handleEdit(row.original.id)} data-bs-toggle="modal" data-bs-target="#updatemodal" className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
                      </OverlayTrigger>
                      <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="button-tooltip-2">Delete Patient</Tooltip>}
                      >
                          <i
                              onClick={() => handleId(row.original.id)}
                              data-bs-target="#delete_model"
                              data-bs-toggle="modal"
                              className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"
                          ></i>
                      </OverlayTrigger>
                  </div>
              ),
          },
      ],
      [handleEdit] // Include handleEdit in the dependency array
  );
  
    
    
  return (
    <div className='container-fluid p-5'>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <BreadCrumb parent="Patients" current="All" />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <FilterPatient />
      </div>
      <div className='card shadow p-4 border-0 rounded-3'>
        <div className='col-12'>
          <button className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fas fa-plus" ></i> Add Patient</button>
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Add New Patient</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id='close-btn' aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                 
                  <div className="form-group field-patient-name required">
                    <label className="form-control-label " htmlFor="patient-name">Name</label>
                    <input type="text" id="patient-name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" name="Patient[name]" maxLength="50" aria-required="true" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-email">
                    <label className="form-control-label " htmlFor="patient-email">Email</label>
                    <input type="email" id="patient-email"  value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" name="Patient[email]" maxLength="50" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-email">
                    <label className="form-control-label " htmlFor="patient-email">Country</label>
                    <select className='form-select' value={country} onChange={(e)=>setCountry(e.target.value)} >
                        <option>Choose...</option>
                        <option>Pakistan</option>
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-age required">
                    <label className="form-control-label " htmlFor="patient-age">Age</label>
                    <input type="number" id="patient-age"  value={age} onChange={(e)=>setAge(e.target.value)} className="form-control" name="Patient[age]" aria-required="true" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-whatsapp_no">
                    <label className="form-control-label " htmlFor="patient-whatsapp_no">Whatsapp No</label>
                    <input type="number" id="patient-whatsapp_no" value={whatsappno} onChange={(e)=>setWhatsappno(e.target.value)} className="form-control" name="Patient[whatsapp_no]" maxLength="17" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-address w-100">
                    <label className="form-control-label " htmlFor="patient-address">Referral By</label>
                    <input type="text" id="patient-address" value={referral} onChange={(e)=>setReferral(e.target.value)} className="form-control" name="Patient[address]" maxLength="255" />
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group field-patient-phone_no required">
                    <label className="form-control-label " htmlFor="patient-phone_no">Phone No</label>
                    <input type="number" id="patient-phone_no" value={phoneno} onChange={(e)=>setPhoneno(e.target.value)} className="form-control" name="Patient" maxLength="17" aria-required="true" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-cnic">
                    <label className="form-control-label " htmlFor="patient-cnic">Cnic</label>
                    <input type="text" id="patient-cnic" value={cnic} onChange={(e)=>setCnic(e.target.value)} className="form-control" name="Patient" maxLength="15" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-email">
                    <label className="form-control-label " htmlFor="patient-email">City</label>
                    <input type="email" id="patient-email"  value={city} onChange={(e)=>setCity(e.target.value)} className="form-control" name="Patient[email]" maxLength="50" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-gender required">
                    <label className="form-control-label " htmlFor="patient-gender">Gender</label>
                    <select className='form-select'  value={gender} onChange={(e)=>setGender(e.target.value)}>
                      <option>Choose..</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-relationship required">
                    <label className="form-control-label " >Relationship</label>
                    <select className='form-select'  value={relationship} onChange={(e)=>setRelationship(e.target.value)}>
                      <option>Choose..</option>
                      <option>Married</option>
                      <option>Single</option>
                      <option>Other</option>
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                  
                  <div className="form-group field-patient-address w-100">
                    <label className="form-control-label"  htmlFor="patient-address">Address</label>
                    <input type="text" id="patient-address" value={address} onChange={(e)=>setAdress(e.target.value)} className="form-control"  />
                    <p className="help-block help-block-error"></p>
                  </div>
                  

                </div>
             
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
                    </div>
                </div>
  </div>
              </div>
        </div>
        <div className="card shadow border-0 p-3">

              <div className="mb-3 text-center">
              {loading ? (
              <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
              </div>
              ) : (
              <>
              <Table columns={columns} data={data} />
              </>
              )}
              <div className='d-block mt-3'>
              <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              />
              </div>

              </div>



              </div>


</div>
{/* DELETE Modal */}
<div className='modal fade' id={`delete_model`} tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" >
<div className="modal-dialog modal-dialog-centered modal-lg modal-add-new-role" style={{width:"480px"}}>
<div className="modal-content p-3 p-md-5">
<button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
<div className="modal-body">
<div className="text-center mb-4">
<h3 className="role-title mb-2">Confirm Delete</h3>
<p className="text-muted">Are you sure you want to delete this item?</p>
</div>
<div className="col-12 mb-4">
<input type="hidden" name="id" value={id} className="form-control" />
</div>
<div className="col-12 text-center mt-4">
<button data-bs-dismiss="modal" aria-label="Close" onClick={handleDelete}   className="btn btn-danger me-2">
<span><small>Delete Patient</small></span></button>
<button type="reset" className="btn btn-label-secondary waves-effect"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
</div>
</div>
</div>
</div>
</div>

{/* Edit Modal */}

<div className="modal fade" id="updatemodal" tabIndex="-1" aria-hidden="true">
<div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Add New Patient</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id='close-btn-update' aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                 
                  <div className="form-group field-patient-name required">
                    <label className="form-control-label " htmlFor="patient-name">Name</label>
                    <input type="text" id="patient-name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" name="Patient[name]" maxLength="50" aria-required="true" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-email">
                    <label className="form-control-label " htmlFor="patient-email">Email</label>
                    <input type="email" id="patient-email"  value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" name="Patient[email]" maxLength="50" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-email">
                    <label className="form-control-label " htmlFor="patient-email">Country</label>
                    <select className='form-select' value={country} onChange={(e)=>setCountry(e.target.value)} >
                        <option>Choose...</option>
                        <option>Pakistan</option>
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-age required">
                    <label className="form-control-label " htmlFor="patient-age">Age</label>
                    <input type="number" id="patient-age"  value={age} onChange={(e)=>setAge(e.target.value)} className="form-control" name="Patient[age]" aria-required="true" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-whatsapp_no">
                    <label className="form-control-label " htmlFor="patient-whatsapp_no">Whatsapp No</label>
                    <input type="number" id="patient-whatsapp_no" value={whatsappno} onChange={(e)=>setWhatsappno(e.target.value)} className="form-control" name="Patient[whatsapp_no]" maxLength="17" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-address w-100">
                    <label className="form-control-label " htmlFor="patient-address">Referral By</label>
                    <input type="text"  value={referral} onChange={(e)=>setReferral(e.target.value)} className="form-control"   />
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group field-patient-phone_no required">
                    <label className="form-control-label " htmlFor="patient-phone_no">Phone No</label>
                    <input type="number" id="patient-phone_no" value={phoneno} onChange={(e)=>setPhoneno(e.target.value)} className="form-control" name="Patient" maxLength="17" aria-required="true" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-cnic">
                    <label className="form-control-label " htmlFor="patient-cnic">Cnic</label>
                    <input type="text" id="patient-cnic" value={cnic} onChange={(e)=>setCnic(e.target.value)} className="form-control" name="Patient" maxLength="15" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-email">
                    <label className="form-control-label " htmlFor="patient-email">City</label>
                    <input type="email" id="patient-email"  value={city} onChange={(e)=>setCity(e.target.value)} className="form-control" name="Patient[email]" maxLength="50" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-gender required">
                    <label className="form-control-label " htmlFor="patient-gender">Gender</label>
                    <select className='form-select'  value={gender} onChange={(e)=>setGender(e.target.value)}>
                      <option>Choose..</option>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                  <div className="form-group field-patient-relationship required">
                    <label className="form-control-label " htmlFor="patient-relationship">Relationship</label>
                    <select className='form-select'  value={relationship} onChange={(e)=>setRelationship(e.target.value)}>
                      <option>Choose..</option>
                      <option>Married</option>
                      <option>Single</option>
                      <option>Other</option>
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                  
                  <div className="form-group field-patient-address w-100">
                    <label className="form-control-label"  htmlFor="patient-address">Address</label>
                    <input type="text"  value={address} onChange={(e)=>setAdress(e.target.value)} className="form-control" />
                    <p className="help-block help-block-error"></p>
                  </div>
                  

                </div>
             
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" id='close-update-btn' onClick={handleUpdate}  className="btn btn-primary">Submit</button>
                    </div>
                </div>
  </div>
    </div>

{/*  */}


      </div>

    

  );
} 
