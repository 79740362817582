import React, { useState } from 'react';

function FilterLabForms() {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="card shadow p-4 border-0 rounded-3 mb-3">
      <div className="card-title">Search LabForms</div>
      <div className="icon-wrapper position-absolute" style={{ right: '30px' }}>
        <i
          id="toggleIcon"
          className={`fas mb-2 text-primary fa-angle-double-down ${
            isToggled ? 'rotate' : ''
          }`}
          onClick={handleToggle}
        ></i>
      </div>
      {isToggled && (
        <div className="form-container"> {/* Added a class for animation */}
          <form action="https://crm.khybercity.com.pk/assigned-dealers" id="dealer_form" method="POST">
            <input type="hidden" name="_token" value="RaPXXuaHanOHQ7CXqGdFCSbx3K4yRd67LlGyvuzf" autoComplete="off" />
            {/* ... your dealer form code  */}
          </form>
          <form action="https://crm.khybercity.com.pk/files-filter" method="POST" id="search-file">
        <input type="hidden" name="_token" value="RaPXXuaHanOHQ7CXqGdFCSbx3K4yRd67LlGyvuzf" autoComplete="off" />        <div className="row pt-2 mt-3 border-top" id="hiddenDiv" >
            <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
                <label for="" className=" me-2 mb-1">Name</label>
                <input type="text" className="form-control" placeholder="" name="Enter Patient ID" />
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6  mb-2">
                <label for="" className=" me-2 mb-1">Status</label>
                <select className='form-select'>
                <option>Choose...</option>
                    <option>Active</option>
                    <option>InActive</option>
                </select>
            </div>
          
           
           
          

            <div className="col-md-12 mt-3">
               <button type="button" className="btn btn-primary mt-4 ms-auto d-block waves-effect waves-light" id="filter_btn">Apply Filter</button>
            </div>
        </div>
    </form>
        </div>
      )}
    </div>
  );
}

export default FilterLabForms;
