import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Carousel(props) {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: <button className="slick-prev">Previous</button>,
        nextArrow: <button className="slick-next">Next</button>,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: true, // Show arrows on smaller screens
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true, // Show arrows on even smaller screens
                },
            },
        ],
    };

    return (
        <div className='d-block w-100 position-relative my-3 px-2'>
          <style>
                {`
                .slick-prev {
                    filter: brightness(0.2) !important;
                }
                .slick-next {
                    filter: brightness(0.2) !important;
                }
                `}
            </style>
            <Slider {...settings}>
                {props.data.map(item => (
                    <div key={item.id} 
                  className={`boxstyle px-2 hover-colored ${item.id === props.active ? 'active-box' : ''}`} 
                    onClick={() => props.onItemClicked(item.id)}>
                        <h6>{item.name}</h6>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
