
import avatar from '../../../src/assets/uploads/avatar2.jpg'
import bgavatar from '../../../src/assets/uploads/bg-avatar.jpg'
import React, { useState } from 'react';

export default function MyProfile() {
    const [image, setImage] = useState(null);
    const [activeTab, setActiveTab] = useState('account');


    const handleTabClick = (tab) => {
        setActiveTab(tab);
      };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
  return (
    <div>
          <div className="container-fluid m-auto mt-4">
        <div className="row flex flex-wrap mx-5">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <h4><span className='text-muted'>Profile</span> / <span>User</span></h4>
            </div>
            
            <div className='col-12 py-3'>
                <button  className={`btn btn-${activeTab === 'account' ? 'primary' : 'outline-primary'} px-4 me-3`}
          onClick={() => handleTabClick('account')}><i className="fa-regular fa-user pe-2"></i> Account</button>
                <button   className={`btn btn-${activeTab === 'security' ? 'primary' : 'outline-primary'} px-4`}
          onClick={() => handleTabClick('security')}><i className="fa-regular fa-user pe-2"></i> Security</button>
            </div>
            {/* Account */}
            {activeTab === 'account' && (
           <div className='col-12'>
           <div className="card mb-4">
                    <h5 className="card-header">Account</h5>
                    <div className="card-body">
                    
                <div className='d-flex flex-row flex-wrap py-3'>
                <input type="file" id='upload_photo' onChange={handleImageChange} accept="image/*" className='d-none'/>
      {image && (
        <div>
        
          <img src={image} alt="Preview" className='rounded-circle shadow me-3' style={{ maxWidth: '300px',objectFit:'cover',maxHeight:'130px' }} />
        </div>
      )}
      <div>
                <label htmlFor='upload_photo' className='btn btn-primary  px-4 mt-4'>Upload Image</label>
      </div>
     
    </div>
            </div>
            <div className='container-fluid'>
            <div className="row">
                          <div className="mb-3 col-md-6">
                            <label for="firstName" className="form-label">First Name</label>
                            <input className="form-control" type="text" id="firstName" name="firstName" value="John"  />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="lastName" className="form-label">Last Name</label>
                            <input className="form-control" type="text" name="lastName" id="lastName" value="Doe" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="email" className="form-label">E-mail</label>
                            <input className="form-control" type="text" id="email" name="email" value="john.doe@example.com" placeholder="john.doe@example.com" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="organization" className="form-label">Organization</label>
                            <input type="text" className="form-control" id="organization" name="organization" value="Pixinvent"/>
                          </div>
                          <div className="mb-3 col-md-6">
                            <label className="form-label" for="phoneNumber">Phone Number</label>
                            <div className="input-group input-group-merge">
                              <span className="input-group-text">US (+1)</span>
                              <input type="text" id="phoneNumber" name="phoneNumber" className="form-control" placeholder="202 555 0111" />
                            </div>
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="address" className="form-label">Address</label>
                            <input type="text" className="form-control" id="address" name="address" placeholder="Address" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="state" className="form-label">State</label>
                            <input className="form-control" type="text" id="state" name="state" placeholder="California" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="zipCode" className="form-label">Zip Code</label>
                            <input type="text" className="form-control" id="zipCode" name="zipCode" placeholder="231465" maxLength="6" />
                          </div>
                          <div className="mb-3 col-md-6">
                            <label className="form-label" for="country">Country</label>
                            <div className="position-relative"><select id="country" className="select2 form-select select2-hidden-accessible" data-select2-id="country" tabIndex="-1" aria-hidden="true">
                              <option value="" data-select2-id="2">Select</option>
                              <option value="Australia">Australia</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Canada">Canada</option>
                              <option value="China">China</option>
                              <option value="France">France</option>
                              <option value="Germany">Germany</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Japan">Japan</option>
                              <option value="Korea">Korea, Republic of</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Russia">Russian Federation</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">United Arab Emirates</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="United States">United States</option>
                            </select></div>
                          </div>
                          <div className="mb-3 col-md-6">
                            <label for="language" className="form-label">Language</label>
                            <div className="position-relative"><select id="language" className="select2 form-select select2-hidden-accessible" data-select2-id="language" tabIndex="-1" aria-hidden="true">
                              <option value="" data-select2-id="4">Select Language</option>
                              <option value="en">English</option>
                              <option value="fr">French</option>
                              <option value="de">German</option>
                              <option value="pt">Portuguese</option>
                            </select></div>
                          </div>
                          <div className="mb-3 col-md-12">
                          <div className="mt-2">
                          <button type="submit" className="btn btn-primary me-2 waves-effect waves-light">Save changes</button>
                          <button type="reset" className="btn btn-label-secondary waves-effect">Cancel</button>
                        </div>
                          </div>
                        
                        </div>
            </div>
           </div>
</div>
  )}
{/*  */}



{/* Security */}
{activeTab === 'security' && (
<div className='col-12'>
<div className="card mb-4">
                    <h5 className="card-header">Change Password</h5>
                    <div className="card-body">
                      <form id="formAccountSettings" method="POST" onsubmit="return false">
                        <div className="row">
                          <div className="mb-3 col-md-6 form-password-toggle">
                            <label className="form-label" for="currentPassword">Current Password</label>
                            <div className="input-group input-group-merge">
                              <input className="form-control" type="password" name="currentPassword" id="currentPassword" placeholder="············" />
                              <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 col-md-6 form-password-toggle">
                            <label className="form-label" for="newPassword">New Password</label>
                            <div className="input-group input-group-merge">
                              <input className="form-control" type="password" id="newPassword" name="newPassword" placeholder="············" />
                              <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                            </div>
                          </div>

                          <div className="mb-3 col-md-6 form-password-toggle">
                            <label className="form-label" for="confirmPassword">Confirm New Password</label>
                            <div className="input-group input-group-merge">
                              <input className="form-control" type="password" name="confirmPassword" id="confirmPassword" placeholder="············" />
                              <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                            </div>
                          </div>
                          <div className="col-12 mb-4">
                            <h6>Password Requirements:</h6>
                            <ul className="ps-3 mb-0">
                              <li className="mb-1">Minimum 8 characters long - the more, the better</li>
                              <li className="mb-1">At least one lowercase character</li>
                              <li>At least one number, symbol, or whitespace character</li>
                            </ul>
                          </div>
                          <div>
                            <button type="submit" className="btn btn-primary me-2 waves-effect waves-light">Save changes</button>
                            <button type="reset" className="btn btn-label-secondary waves-effect">Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
</div>

)}
{/*  */}
        </div>
    </div>
    </div>
  )
}
