import React from 'react';
import { useTable, usePagination } from 'react-table';
import fakedata from '../../fakedata/labforms.json';
import FilterLabForms from '../../components/FilterLabForms';
import BreadCrumb from '../../components/BreadCrumb';

export default function Index() {
    const data = React.useMemo(() => fakedata, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: 's.no',
                Cell: ({ row }) => row.index + 1,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'test_name',
                accessor: 'test_name',
            },
            
            {
                Header: 'Created At',
                accessor: 'created_on',
            },
            {
                Header: 'Created By',
                accessor: 'created_by',
            },
            {
                Header: <div className="text-center">Actions</div>,
                accessor: 'actions', // Unique identifier for the action column
                Cell: () => (
                    <div className="d-flex justify-content-center">
                        <i className="fa-solid fa-eye bg-info text-white rounded-circle p-2 me-1 hover"></i>
                        <i className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
                        <i className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"></i>
                    </div>
                ),
            },
        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
        gotoPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );

    return (
        <div className='container-fluid p-5'>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <BreadCrumb parent="Lab Forms" current="All" />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <FilterLabForms />
            </div>
            <div className='card shadow p-4 border-0 rounded-3'>
                <div className='col-12'>
                    <button className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fas fa-plus" ></i> Add LabForm</button>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Add LabForm</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form id="form" className="m-form m-form--state" action="/web/category/create" method="post" autoComplete="off">
                                        <input type="hidden" name="_csrf" value="leuAyehek5732OGG1S9Vt97v-Nd0HBE2ypHMjysxUnWi0tLxrW3WyZ2hh9PkYw_O6ZvL4xgpJ0az3bq7EgMEIA==" />
                                        <div className="form-group field-category-name required">
                                            <label className="form-control-label " for="category-name">Name</label>
                                            <input type="text" id="category-name" className="form-control" name="Category[name]" maxLength="255" aria-required="true" />
                                            <p className="help-block help-block-error"></p>
                                        </div>
                                        <div className="form-group field-category-tree">
                                            <label className="form-control-label " for="category-tree">Title</label>
                                            <input className='form-control' />
                                            <p className="help-block help-block-error"></p>
                                        </div>
                                        <div className="form-group field-category-status">
                                            <label className="form-control-label " for="category-status">Item Names</label>
                                            <select id="category-status" className="form-control" name="Category[status]">
                                                <option>Choose...</option>
                                                <option value="1">Active</option>
                                                <option value="0">InActive</option>
                                            </select>
                                            <p className="help-block help-block-error"></p>
                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table {...getTableProps()} className="table table-bordered">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div>
                    <button className='btn btn-primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </button>{' '}
                    <button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>{' '}
                    <span>
                        Page{' '}
                        {pageOptions.map((page, index) => (
                            <button className='btn btn-primary me-2' key={index} onClick={() => gotoPage(index)} disabled={pageIndex === index}>
                                {index + 1}
                            </button>
                        ))}
                    </span>
                </div>
            </div>

        </div>

    );
} 
