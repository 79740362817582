import React from 'react'
import Card from '../components/Card'
import { PieChart, Pie, Cell, LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ResponsiveContainer } from 'recharts';

export default function dashboard() {

  const data = [
    { name: 'Jan', series1: 10, series2: 21, series3: 40 },
    { name: 'Feb', series1: 12, series2: 25, series3: 8 },
    { name: 'Mar', series1: 12, series2: 18, series3: 6 },
    // Add more data points here
  ];

  const data3 = [
    { name: 'Jan', series1: 2, series2: 21, series3: 40 },
    { name: 'JUL', series1: 30, series2: 25, series3: 8 },
    { name: 'DEC', series1: 100, series2: 18, series3: 6 },
  ];
  const data3_2 = [
    { name: 'Jan', series1: 30, series2: 21, series3: 40 },
    { name: 'JUL', series1: 2, series2: 25, series3: 8 },
    { name: 'DEC', series1: 100, series2: 18, series3: 6 },
  ];
  const data4 = [
    { name: '0-10', value: 400 },
    { name: '10-20', value: 300 },
    { name: '20-30', value: 200 },
    { name: '30-50', value: 100 },
  ];
  const data5 = [
    { name: 'Male', value: 400 },
    { name: 'Female', value: 300 },
    { name: 'Others', value: 100 },


  ];
  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7f00'];

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map((i) => i.uv));
    const dataMin = Math.min(...data.map((i) => i.uv));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();
  return (
    <div>
      <div className="container px-5 mt-3">
        <div className="row">

          <div className='col-md-3 col-lg-3'>
            <Card iconclassName="fa-solid fa-hospital-user"
              heading="Total Patietnt"
              count="230" />
          </div>
          <div className='col-md-3 col-lg-3'>
            <Card iconclassName="fa-solid fa-wheelchair"
              heading="Today Patients"
              count="230" />
          </div>
          <div className='col-md-3 col-lg-3'>
            <Card iconclassName="fa-regular fa-building"
              heading="Departments"
              count="23+" />
          </div>
          <div className='col-md-3 col-lg-3'>
            <Card iconclassName="fa-solid fa-hospital-user"
              heading="Earnings"
              count="23000 Pkr" />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-12 col-12'>
            <h4 className='mt-4'>Patient Report</h4>
            <LineChart width={1200} height={400} data={data} margin={{ top: 50, right: 30, left: 0, bottom: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="series1" stroke="#336CFB" strokeWidth={3} />
              <Line type="monotone" dataKey="series2" stroke="red" strokeWidth={3} />
              <Line type="monotone" dataKey="series3" stroke="green" strokeWidth={3} />
              {/* Add more Line components for additional series */}
            </LineChart>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6 col-sm-12 col-lg-6'>

            <h4>Monthly Revenue</h4>
            <AreaChart width={600} height={400} data={data3} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="series1" fill="#336cfb" stackId="1" />

            </AreaChart>

          </div>
          <div className='col-md-6 col-sm-12 col-lg-6'>

            <h4>Weekly Revenue</h4>
            <AreaChart width={600} height={400} data={data3_2} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="series1" fill="#336cfb" stackId="1" />

            </AreaChart>

          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-6'>
            <h4>Patient Age</h4>
            <PieChart width={600} height={400}>
              <Pie
                data={data4}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={120}
                fill="#8884d8"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
          <div className='col-6'>
            <h4>Patient Gender</h4>
            <PieChart width={600} height={400}>
              <Pie
                data={data5}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={120}
                fill="#8884d8"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        </div>

      </div>

    </div>
  )
}
