import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../components/BreadCrumb';
import { PERMISSION_API, CREATE_PERMISSION, DELETE_PERMISSION,EDIT_PERMISSION, UPDATE_PERMISSION } from '../../../routes/api/Index';
import { toast } from 'react-toastify';
import Table from '../../../components/Table';
import ReactPaginate from 'react-paginate';
export default function Index() {
  
  const token = localStorage.getItem('token');
  const [permissionName, setPermissionName] = useState(['']);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [id,setId]=useState('');

  const [data,setData]=useState([]);

  function resetValues() {
    setPermissionName('');
    setParentCategory('');
    setCategory('');
  }


  useEffect(() => {
    fetchData();
  }, [pageIndex]);




  function assignvalues(name,child_category,parent_category){
    setPermissionName(name);
    setParentCategory(parent_category);
    setCategory(child_category);
  }
  

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1
    setPageIndex(selectedPage);
   
  };


  const handleSubmit = async () => {
    try {
      
      const requestBody = JSON.stringify({
        permissionName,
        category,
        parentCategory
      });
      
      const response = await fetch(CREATE_PERMISSION, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }

      if (responseData.status === 200) {
        toast.success(responseData.message || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        fetchData();
       
        resetValues();
       
        let close=document.getElementById('close-btn');
        close.click();
      } else {
        toast.error(`${responseData.message}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }

      console.log('Form submitted successfully');
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleEdit=async(id)=>{
   
    const response = await fetch(`${EDIT_PERMISSION}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    let permssion_date=responseData.data;
    assignvalues(permssion_date.name,permssion_date.category,permssion_date.parent_category);
    if(responseData.status===200){
      setId(id);
      toast.success(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    
    } 
    else{
      toast.error(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    
    
  }


  const handleUpdate = async () => {
    try {
      
      const requestBody = JSON.stringify({
        id,
        permissionName,
        category,
        parentCategory
      });
      
      const response = await fetch(UPDATE_PERMISSION, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }

      if (responseData.status === 200) {
        toast.success(responseData.message || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        fetchData();
       
        resetValues();
       
        let close=document.getElementById('close-update-btn');
        close.click();
      } else {
        toast.error(`${responseData.message}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }

      console.log('Form submitted successfully');
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Error submitting form:', error);
    }
  };


  const handleDelete = async () => {
    // Perform delete operation using the id
    try {

      const response = await fetch(`${DELETE_PERMISSION}/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Network response was not ok');
      }
  
      // Handle successful deletion
      console.log('Item deleted successfully');
      toast.success(responseData.message || 'Item deleted successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      fetchData();
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }
  
  function handleId(id){
    setId(id);
  }
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${PERMISSION_API}?page=${pageIndex}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    setData(responseData.data);
    setPageCount(responseData.last_page);
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setLoading(false);
  }

    }
  

    const columns = React.useMemo(
      () => [
        {
          Header: 'S.No',
          accessor: 's.no',
          Cell: ({ row }) => row.index + 1,
        },
        {
          Header: 'Permission',
          accessor: 'name',
        },
        {
          Header: 'Guard Name',
          accessor: 'guard_name',
        },
        {
          Header: 'Parent Category',
          accessor: 'parent_category',
        },
        {
          Header: 'Child Category',
          accessor: 'category',
        },
       
        {
          Header: 'Action',
          Cell:({ row }) => (
            <div className="d-flex flex-row justify-content-center">
              <i onClick={() => handleEdit(row.original.id)}   data-bs-toggle="modal" data-bs-target="#updatepermission" className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
              <i onClick={() => handleId(row.original.id)}   data-bs-target={`#delete_model`} data-bs-toggle="modal"  className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"></i>
            </div>
          ),
        },
      ],
      []
    );
    
  
    
    return (
        <>
        
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <BreadCrumb parent="Permissions" current="All" />
      </div>
                <p>Each category (Basic, Professional, and Business) includes the four predefined roles shown below.</p>
                <div className="row mb-3">
                    <div className="col-12">
                        <button className="btn add-new btn-primary mb-3 mb-md-0 float-end" tabIndex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="modal" data-bs-target="#addPermissionModal"><span>Add Permission</span></button>
                    </div>
                    <div className="modal fade" id="addPermissionModal" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3 p-md-5">
                                <button
                                    type="button"
                                    id='close-btn'
                                    className="btn-close btn-pinned"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                <div className="modal-body">
                                    <div className="text-center mb-4">
                                        <h3 className="mb-2">Add New Permission</h3>
                                        <p className="text-muted">Permissions you may use and assign to your users.</p>
                                    </div>
                                  
                                    <div className="col-12 mb-3">
                                            <label className="form-label" for="modalPermissionName">Parent Category</label>
                                            <select onChange={(e)=>setParentCategory(e.target.value)} value={parentCategory} className='form-select'>
                                              <option value="">Choose...</option>
                                              <option>Pos</option>
                                              <option>Sales</option>
                                              <option>Reception</option>
                                              <option>Departments</option>
                                              <option>Inventory</option>
                                              <option>Setting</option>
                                              <option>Permissions</option>
                                              <option>Roles</option>


                                            </select>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" for="modalPermissionName">Child Category</label>
                                            <select onChange={(e)=>setCategory(e.target.value)} value={category} className='form-select'>
                                            <option value="">Choose...</option>
                                          
                                            <optgroup label = "Setting">
                                              <option>Roles</option>
                                              <option>Permissions</option>
                                              <option>Users</option>
                                              <option>Application Setting</option>
                                            </optgroup>
                                            <optgroup label = "Inventory">
                                              <option>Products</option>
                                              <option>Purchase</option>
                                              <option>Categories</option>
                                              <option>Payment</option>
                                            </optgroup>
                                            </select>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" for="modalPermissionName">Permission Name</label>
                                            <input
                                                value={permissionName}
                                                onChange={(e)=>setPermissionName(e.target.value)}
                                                type="text"
                                                id="modalPermissionName"
                                                name="name"
                                                className="form-control"
                                                placeholder="Permission Name"
                                                />
                                        </div>
                                        <div className="col-12 mb-2">

                                        </div>
                                        <div className="col-12 text-center demo-vertical-spacing">
                                            <button onClick={handleSubmit} type="button" className="btn btn-primary me-sm-3 me-1">Create Permission</button>
                                            <button
                                                type="reset"
                                                className="btn btn-label-secondary"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                Discard
                                            </button>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="card shadow border-0 p-3">

                <div className="mb-3 text-center">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <Table columns={columns} data={data} />
            </>
          )}
          <div className='d-block mt-3'>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
                
                </div>
               


            </div>


           </div>
            {/* DELETE Modal */}
      <div className='modal fade' id={`delete_model`} tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" >
      <div className="modal-dialog modal-dialog-centered modal-lg modal-add-new-role" style={{width:"480px"}}>
        <div className="modal-content p-3 p-md-5">
          <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body">
            <div className="text-center mb-4">
              <h3 className="role-title mb-2">Confirm Delete</h3>
              <p className="text-muted">Are you sure you want to delete this item?</p>
            </div>
            <div className="col-12 mb-4">
              <input type="hidden" name="id" value={id} className="form-control" />
            </div>
            <div className="col-12 text-center mt-4">
            <button data-bs-dismiss="modal" aria-label="Close" onClick={handleDelete}   className="btn btn-danger me-4">
            <span><small>Confirm Delete</small></span></button>
              <button type="reset" className="btn btn-label-secondary waves-effect"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            </div>
          </div>
        </div>
      </div>
              </div>
              
          {/* Edit Modal */}

          <div className="modal fade" id="updatepermission" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3 p-md-5">
                                <button
                                    type="button"
                                    id='close-update-btn'
                                    className="btn-close btn-pinned"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                <div className="modal-body">
                                    <div className="text-center mb-4">
                                        <h3 className="mb-2">Update Permission</h3>
                                        <p className="text-muted">Permissions you may use and assign to your users.</p>
                                    </div>
                                   
                                    <div className="col-12 mb-3">
                                            <label className="form-label" for="modalPermissionName">Parent Category</label>
                                            <select onChange={(e)=>setParentCategory(e.target.value)} value={parentCategory} className='form-select'>
                                              <option>Choose...</option>
                                              <option>Pos</option>
                                              <option>Sales</option>
                                              <option>Reception</option>
                                              <option>Departments</option>
                                              <option>Inventory</option>
                                              <option>Setting</option>
                                              <option>Permissions</option>
                                              <option>Roles</option>


                                            </select>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" for="modalPermissionName">Child Category</label>
                                            <select onChange={(e)=>setCategory(e.target.value)} value={category} className='form-select'>
                                            <option>Choose...</option>
                                          
                                            <optgroup label = "Setting">
                                              <option>Roles</option>
                                              <option>Permissions</option>
                                              <option>Users</option>
                                              <option>Application Setting</option>
                                            </optgroup>
                                            <optgroup label = "Inventory">
                                              <option>Products</option>
                                              <option>Purchase</option>
                                              <option>Categories</option>
                                              <option>Payment</option>
                                            </optgroup>
                                            </select>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" for="modalPermissionName">Permission Name</label>
                                            <input
                                                value={permissionName}
                                                onChange={(e)=>setPermissionName(e.target.value)}
                                                type="text"
                                                id="modalPermissionName"
                                                name="name"
                                                className="form-control"
                                                placeholder="Permission Name"
                                                 />
                                        </div>
                                        <div className="col-12 mb-2">

                                        </div>
                                        <div className="col-12 text-center demo-vertical-spacing">
                                            <button onClick={handleUpdate} type="button" className="btn btn-primary me-sm-3 me-1">Update Permission</button>
                                            <button
                                            
                                                type="reset"
                                                className="btn btn-label-secondary"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                Discard
                                            </button>
                                        </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>

          {/*  */}


           </div>
        </>
    )
}
