import React, { useEffect, useState } from 'react';
import avatar from '../../../assets/avatars/1.png';
import girl from '../../../assets/uploads/add-new-roles.png';
import { ROLES_API, CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from '../../../routes/api/Index';
import { toast } from 'react-toastify';


export default function Index() {
  const token = localStorage.getItem('token');
  const [roles, setRoles] = useState(['']);
  
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

 

  function resetValues() {
    setName('');
  }

  const handleSubmit = async () => {
    try {
      const requestBody = JSON.stringify({
        name,
      });
      ;



      const response = await fetch(CREATE_ROLE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      });

      const responseData = await response.json();

      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }

      if (responseData.status === 200) {
        toast.success(responseData.message || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        fetchData();
        const offcanvasAddUser = document.getElementById('close');
        offcanvasAddUser.click();
        resetValues();
      } else {
        toast.error(`${responseData.message}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }

      console.log('Form submitted successfully');
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleDelete = async (index) => {
    // Perform delete operation using the id
    try {
      const response = await fetch(`${DELETE_ROLE}/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Network response was not ok');
      }
  
      // Handle successful deletion
      console.log('Item deleted successfully');
      toast.success(responseData.message || 'Item deleted successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
  
      // Close the modal
      let btn_close=document.getElementById(`delete_model_${index}`);
      btn_close.classList.remove('show');
      
      // Refetch data after deletion
      fetchData();
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }
  


  const fetchData = async () => {
    const response = await fetch(`${ROLES_API}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    const responseData = await response.json();

    setRoles(responseData.data);
  }

  return (
    <>
      <div className="content-wrapper py-5">
        <div className="container-xxl flex-grow-1 container-p-y">
          <h4 className="mb-4">Roles List</h4>
          <p className="mb-4">
            A role provided access to predefined menus and features so that depending on <br />
            assigned role an administrator can have access to what user needs.
          </p>
          <div className="row g-4">
            {roles.map((element, index) => (
              <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                <div className="card shadow border-0 py-3 px-2">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h6 className="fw-normal mb-2">Total 4 users</h6>
                      
                    </div>
                    <div className="d-flex justify-content-between align-items-end mt-1">
                      <div className="role-heading">
                        <h4 className="mb-3 text-capitalize">{element.name}</h4>
                        <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Edit Role 
</button>
                        <button 
  
  onClick={(e) => setId(element.id)}     
  data-bs-target={`#delete_model_${index}`} 
  data-bs-toggle="modal"
  className="btn btn-danger"
>
  <span> <small >Delete Role</small></span>
</button>

                      </div>
                     
                    </div>
                  </div>
                </div>
               {/* DELETE Modal */}
               <div className='modal fade' id={`delete_model_${index}`} tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden={!deleteModelOpen}>
      <div className="modal-dialog modal-dialog-centered modal-lg modal-add-new-role" style={{width:"480px"}}>
        <div className="modal-content p-3 p-md-5">
          <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body">
            <div className="text-center mb-4">
              <h3 className="role-title mb-2">Confirm Delete</h3>
              <p className="text-muted">Are you sure you want to delete this item?</p>
            </div>
            <div className="col-12 mb-4">
              <input type="hidden" name="id" value={element.id} className="form-control" />
            </div>
            <div className="col-12 text-center mt-4">
            <button data-bs-dismiss="modal" aria-label="Close"  onClick={() => handleDelete(index)} className="btn btn-danger me-4">
            <span><small>Delete Role</small></span></button>
              <button type="reset" className="btn btn-label-secondary waves-effect"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            </div>
          </div>
        </div>
      </div>
              </div>

              {/* EDIT MODAL */}
            
    
               {/*  */}
              </div>

            ))}
            {/* Add Role */}
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="card h-100 shadow border-0 bg-white">
                <div className="row h-100">
                  <div className="col-sm-5">
                    <div className="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
                      <img
                        src={girl}
                        className="img-fluid mt-sm-4 mt-md-0"
                        alt="add-new-roles"
                        width="83"
                      />
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="card-body text-sm-end text-center ps-sm-0">
                      <button
                        data-bs-target="#addRoleModal"
                        data-bs-toggle="modal"
                        className="btn btn-primary mb-2 text-nowrap add-new-role"
                      >
                        Add New Role
                      </button>
                      <p className="mb-0 mt-1">Add role, if it does not exist</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade " id="addRoleModal" tabIndex="-1" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-add-new-role">

          <div className="modal-content p-3 p-md-5">
            <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="text-center mb-4">
                <h3 className="role-title mb-2">Add New Role</h3>
                <p className="text-muted">Set role permissions</p>
              </div>

              <form action="https://scrap.7koncepts.com/roles" className="row g-3" method="POST">
                <div className="col-12 mb-4">
                  <label className="form-label" htmlFor="modalRoleName">Role Name</label>
                  <input type="text" id="modalRoleName" name="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter a role name" tabIndex="-1" /></div>

                <div className="col-12 text-center mt-4">
                  <button type="button" onClick={handleSubmit} className="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                  <button type="reset" className="btn btn-label-secondary waves-effect" id='close' data-bs-dismiss="modal" aria-label="Close">
                    Cancel
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-add-new-role">

          <div className="modal-content p-3 p-md-5">
            <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="text-center mb-4">
                <h3 className="role-title mb-2">Edit Role</h3>
                <p className="text-muted">Set role permissions</p>
              </div>

              <form action="https://scrap.7koncepts.com/roles" className="row g-3" method="POST">
              <div className="col-12">
                          <h5>Role Permissions</h5>
                        
                          <div className="table-responsive">
                            <table className="table table-flush-spacing">
                              <tbody>
                                <tr>
                                <th className='border-0' colSpan={4}><span className='badge bg-primary fs-6'>Setting</span></th>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Roles</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Permission</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Users</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Application Setting</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                {/* 2 */}

                                <tr>
                                <th className='border-0' colSpan={4}><span className='badge bg-primary fs-6'>Inventory</span></th>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Products</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Purchase</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Categories</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                 
                                  <td className="text-nowrap fw-medium">Payment</td>
                                  <td>
                                    <div className="d-flex">
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                        <label className="form-check-label" for="userManagementRead"> Read </label>
                                      </div>
                                      <div className="form-check me-3 me-lg-5">
                                        <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                        <label className="form-check-label" for="userManagementWrite"> Write </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                        <label className="form-check-label" for="userManagementCreate"> Create </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        
                        </div>
                <div className="col-12 text-center mt-4">
                  <button type="button" onClick={handleSubmit} className="btn btn-primary me-sm-3 me-1 waves-effect waves-light">Submit</button>
                  <button type="reset" className="btn btn-label-secondary waves-effect" id='close' data-bs-dismiss="modal" aria-label="Close">
                    Cancel
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
     
    </>
  );
}
