import React from 'react';
import { useTable, usePagination } from 'react-table';
import fakedata from '../../fakedata/data.json';
import FilterTest from '../../components/FilterTest';
import BreadCrumb from '../../components/BreadCrumb';

export default function Pending() {
  const data = React.useMemo(() => fakedata, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: 's.no',
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: 'Patient ID',
        accessor: 'id',
      },
      {
        Header: 'Patient Name',
        accessor: 'name',
      },
      {
        Header: 'Item ID',
        accessor: 'item_id',
      },
      {
        Header: 'Item Name',
        accessor: 'item_name',
      },
      {
        Header: 'Item Price',
        accessor: 'Item_price',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Test Status',
        accessor: 'test_status',
      },
      {
        Header: 'Action',
        Cell: () => (
          <div className='d-flex flex-row justify-content-center'>
            <i className="fa-solid fa-eye bg-info text-white rounded-circle p-2 me-1 hover"></i>
            <i className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle  p-2 me-1 hover"></i>
            <i className="fa-solid fa-trash-can bg-danger text-white rounded-circle  p-2 me-1 hover"></i>
            <i className="fa-solid fa-file-arrow-down bg-primary text-white rounded-circle  p-2 me-1 hover"></i>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className='container-fluid p-5'>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <BreadCrumb parent="Test" current="Pending Test" />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <FilterTest />
      </div>
      <div className='card shadow p-4 border-0 rounded-3'>
     
        <table {...getTableProps()} className="table table-bordered">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <button className='btn btn-primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>{' '}
          <button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>{' '}
          <span>
            Page{' '}
            {pageOptions.map((page, index) => (
              <button className='btn btn-primary me-2' key={index} onClick={() => gotoPage(index)} disabled={pageIndex === index}>
                {index + 1}
              </button>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
}
