import React, { useState } from 'react';

function FilterPatient() {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="card shadow p-4 border-0 rounded-3 mb-3">
      <div className="card-title">Search Patient</div>
      <div className="icon-wrapper position-absolute" style={{ right: '30px' }}>
        <i
          id="toggleIcon"
          className={`fas mb-2 text-primary fa-angle-double-down ${
            isToggled ? 'rotate' : ''
          }`}
          onClick={handleToggle}
        ></i>
      </div>
      {isToggled && (
        <div className="form-container"> {/* Added a class for animation */}
          <form action="https://crm.khybercity.com.pk/assigned-dealers" id="dealer_form" method="POST">
            <input type="hidden" name="_token" value="RaPXXuaHanOHQ7CXqGdFCSbx3K4yRd67LlGyvuzf" autoComplete="off" />
            {/* ... your dealer form code  */}
          </form>
          <form action="https://crm.khybercity.com.pk/files-filter" method="POST" id="search-file">
        <input type="hidden" name="_token" value="RaPXXuaHanOHQ7CXqGdFCSbx3K4yRd67LlGyvuzf" autoComplete="off" />        <div className="row pt-2 mt-3 border-top" id="hiddenDiv" >
            <div className="col-md-2 mb-2">
                <label for="" className=" me-2 mb-1">Reg no</label>
                <input type="text" className="form-control" placeholder="" name="Enter Patient ID" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Name</label>
                <input type="text" className="form-control" placeholder="" name="id_no" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Cnic</label>
                <input type="text" className="form-control" placeholder="" name="id_no" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Phone no</label>
                <input type="text" className="form-control" placeholder="" name="id_no" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Email</label>
                <input type="text" className="form-control" placeholder="" name="id_no" />
            </div>
            
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Gender</label>
                <select name="type" className="form-select ">
                    <option value="">Choose...</option>
                    <option value="residential">Residential</option>
                    <option value="commercial">Commercial</option>
                </select>
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Age</label>
                <input type="text" placeholder="" className="form-control" name="size" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Relationship</label>
                <input type="text" placeholder="" className="form-control" name="size" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Age</label>
                <input type="number" placeholder="" className="form-control" name="size" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Whatsapp no</label>
                <input type="text" placeholder="" className="form-control" name="size" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">City</label>
                <input type="text" placeholder="" className="form-control" name="size" />
            </div>
            <div className="col-md-2  mb-2">
                <label for="" className=" me-2 mb-1">Date</label>
                <input type="number" placeholder="" className="form-control" name="size" />
            </div>
          

            <div className="col-md-12 mt-3">
               <button type="button" className="btn btn-primary mt-4 ms-auto d-block waves-effect waves-light" id="filter_btn">Apply Filter</button>
            </div>
        </div>
    </form>
        </div>
      )}
    </div>
  );
}

export default FilterPatient;
