import React, { useState, useEffect } from 'react';
import { PARENT_CATEGORIES,FIND_SUBCATEGORY } from '../routes/api/Index';
import Carousel from './Carousel';

export default function Departments({getTestData}) {
    const token = localStorage.getItem('token');
    const [data, setData] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [activeItemId, setActiveItemId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

   

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(PARENT_CATEGORIES, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            setData(responseData.data);
            setError(null);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleItemClick = (id) => {
        Subcategories(id);
        setActiveItemId(id);
    };
    
    const Subcategories = async (id) => {
        try {
            setLoading(true);
            const response = await fetch(FIND_SUBCATEGORY + '/' + id, { // Assuming FIND_SUBCATEGORY is a URL and you want to append the id to it
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            setSubcategory(responseData.data);
            setError(null);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    


    return (
        <div className='shadow px-4 py-4'>
            <div className="input-group w-100 ">
                <input type="text" className="form-control" placeholder="Type to search" />
                <div className="input-group-append">
                    <button className="btn btn-secondary " type="button">
                        <i className="fa fa-search"></i>
                    </button>
                </div>


               
            <Carousel data={data} onItemClicked={handleItemClick} active={activeItemId} />
            
                <div className='table-responsive w-100' style={{ height: '350px' }}>
                    <table className='mytable mt-4'>
                        <thead>
                            <tr>
                                <th>Test</th>
                                <th className='text-end'>Charges</th>
                            </tr>
                        </thead>
                        <tbody>
    {loading ? (
        <tr>
        <td colSpan={10}>
        <div className="spinner-border text-primary m-auto d-block " role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            </td></tr>
    ) : error ? (
        <p>Error: {error}</p>
    ) : (
        subcategory.map(element => (
            <tr className='hover-colored'
            onClick={() => getTestData(element.id, element.name, element.price,0)}
            key={element.id}>
                <td>{element.name}</td>
                <td className='text-end'>Rs:{element.price}</td>
            </tr>
        ))
    )}
</tbody>

                    </table>
                </div>
            </div>
        </div>

    )
}
