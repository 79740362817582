import React, { useEffect, useState } from 'react'

import Departments from '../../components/Departments'
import { Slide, toast } from 'react-toastify';
import { CREATE_PATIENT, SEARCH_PATIENT } from '../../routes/api/Index';
import {OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Pos() {
  const token = localStorage.getItem('token');
  const [selectedItems, setSelectedItems] = useState([]);
  const [total, setTotal] = useState({
    total: 0,
    discount: 0,
    grandTotal: 0,
    ordertax: 0,
    totalitems: 0,
  });

  const [discount,setDiscount]=useState(0);
  const [patientData,setPatientData]=useState({
    id:'',
    name: '',
    phoneNo:'',
  });
  
  
  const [data,setData]=useState(null);
  const [loading,setLoading]= useState(null);
  const [errors, setErrors] = useState({});
  const [searchVar, SetsearchVar] = useState('');

  const [formData, setFormData] = useState({
    fullName: '',
    age: '',
    ageType: '',
    gender: '',
    phoneNo: '',
    cnicOrPassport: '',
    relationship: '',
    city: '',
    country: '',
    address: '',
    email: '',
    whatsappno: '',
});
  
  const getTestData = (id, name, price, discount) => {
    if (patientData.name !== '') {
      const newItems = [...selectedItems, { id, name, price, discount }];
      setSelectedItems(newItems);
      calculateTotals(newItems,discount);
    } else {
      toast.error("Select Patient First...!", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const deleteItem = (id) => {
    const newItems = selectedItems.filter(item => item.id !== id);
    setSelectedItems(newItems);
    calculateTotals(newItems,discount);
   
  };

  
  const calculateTotals = (items,discountdata) => {
    const total = items.reduce((sum, item) => sum + item.price, 0);
    const discount = discountdata;
    const ordertax = total * 0.1; // Assuming 10% order tax, adjust as needed
    const grandTotal = total - discount + ordertax;
    const totalItemsCount = items.length;
   
    setTotal({ total, discount, grandTotal, ordertax, totalItemsCount });
    
  };

  const createPayment = () => {
    // Check if patient data exists
    if (patientData.id && patientData.name && patientData.phoneNo) {
      // Perform any other logic here if needed

      // Reset all states
      setPatientData({
        id: '',
        name: '',
        phoneNo: '',
      });

      setSelectedItems([]);

      setTotal({
        total: 0,
        discount: 0,
        grandTotal: 0,
        ordertax: 0,
        totalitems: 0,
      });

      setDiscount(0);
      toast.success('Payment has created successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      SetsearchVar();
      let closebtn=document.getElementById('close-offcanvas');
      closebtn.click();
    } else {
      toast.error( 'Error! Payment cannot be created', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.log('Patient data is incomplete or missing');
    }
  };


  useEffect(() => {
    calculateTotals(selectedItems,discount);
  }, [selectedItems]);


  const validateForm = () => {
  const newErrors = {};

  // Full Name Validation
  if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
  }

  // Age Validation
  if (!formData.age) {
      newErrors.age = 'Age is required';
  } else if (isNaN(formData.age)) {
      newErrors.age = 'Age must be a number';
  }

  // Gender Validation
  if (!formData.gender) {
      newErrors.gender = 'Gender is required';
  }

  // Phone No Validation
  if (!formData.phoneNo.trim()) {
      newErrors.phoneNo = 'Phone Number is required';
  } else if (!/^\d{11}$/.test(formData.phoneNo)) {
      newErrors.phoneNo = 'Phone Number must be 10 digits';
  }

  // CNIC/Passport Validation
  if (!formData.cnicOrPassport.trim()) {
      newErrors.cnicOrPassport = 'CNIC/Passport is required';
  } else if (!/^\d{12}$/.test(formData.cnicOrPassport)) {
      newErrors.cnicOrPassport = 'CNIC/Passport must be 13 digits';
  }

  return newErrors;
};


  const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
      ...formData,
      [name]: value,
  });
  console.log(formData);
};

const handleChangeVal = (e) => {
  setTotal({
    ...total,
    grandTotal: e.target.value
  });
};

  const resetValues=()=>{
  setFormData({
    fullName: '',
    age: '',
    ageType: '',
    gender: '',
    phoneNo: '',
    cnicOrPassport: '',
    relationship: '',
    city: '',
    country: '',
    address: '',
    email: '',
    whatsappno: '',
  });
}


const setPatient =(id,name,phone_no)=>{
  setPatientData({
    id:id,
    name:name,
    phoneNo:phone_no,
  });
  console.log(patientData);
}
   

const handleSubmit = async () => {
  try {
    
    const validationErrors = validateForm();
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {

      const requestBody = JSON.stringify({
        name:formData.fullName,
        age:formData.age,
        city:formData.city,
        country:formData.country,
        gender:formData.gender,
        phoneno:formData.phoneNo,
        email:formData.email,
        cnic:formData.cnicOrPassport,
        whatsappno:formData.whatsappno,
        relationship:formData.relationship,
        address:formData.address
        });

      const response = await fetch(CREATE_PATIENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }
  
      if (responseData.status === 200) {
        const patient=responseData.data;
        setPatient(patient.id,patient.name,patient.phone_no);
        toast.success(responseData.message || 'Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        resetValues();
      
        let close=document.getElementById('close-btn');
        close.click();
      } else {
        toast.error(`${responseData.message}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  } catch (error) {
    toast.error(`${error}`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    console.error('Error submitting form:', error);
  }
};

function removePatient(){
  setPatientData({
    id:'',
    name:'',
    phoneNo:'',
  });
  setSelectedItems([]);
  toast.success("Patient has been removed successfully", {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition:Slide
  });
}

 const searchPatient = async(value)=>{
  SetsearchVar(value);
  fetchData(value);
  
}
const fetchData = async (value) => {
  try {
    setLoading(true);
    const response = await fetch(`${SEARCH_PATIENT}?search=${value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    const responseData = await response.json();
    setData(responseData.data);
    console.log(responseData.data);
    setLoading(false);
  } catch (error) {
    console.error('Error:', error);
  } finally {
  
  }
};

const handleDiscount = (discountValue) => {
  return discountValue;
};

  return (
    <div>
       <div className='container px-5 mt-3'>
        <div className="row">
          <div className='col-md-7 col-lg-7 col-12 col-sm-12 '>
          <div className='row shadow py-4 px-4 '>
    {patientData.name !== '' ? (
    <>  
  <div className='col-lg-12 col-md-12 col-sm-12'>
  <div className='d-flex flex-row bg-primary rounded-pill px-3 py-2'>
  <img src='https://svgsilh.com/svg_v2/659651.svg' className='me-2 shadow rounded-circle bg-white' style={{width:"50px", height:"50px",objectFit:"cover"}} />
  <div className='text-area mx-3 text-white position-relative w-100'>
  <h5 className='mb-0'>{patientData.name}</h5>
  <small className='text-white'>{patientData.phoneNo}</small>
  <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-3">Delete Patient</Tooltip>}
                        >
                         <i onClick={removePatient} className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover position-absolute end-0 top-0 mt-2"></i>
                        </OverlayTrigger>
 
  </div>
  </div>
</div>
                </>
) : 

<>
<div className='col-lg-9 col-md-12 col-sm-12 position-relative'>
    
<input 
    onInput={(e) => searchPatient(e.target.value)} 
    value={searchVar} 
    className='form-control' 
    name='search' 
    placeholder='Search For Patient' 
    autoComplete='off' 
/>

{data !== null ? (  
  <div className='shadow position-absolute bg-white rounded-3 p-3' style={{ maxHeight: "260px", overflowY: "auto" }}>
    {loading ? (
      <div className="spinner-border text-primary d-block m-auto" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    ) : (
      <>
        <ul className='list-unstyled'>
          {data.map(element => (
            <li key={element.id} onClick={()=>setPatient(element.id,element.name,element.phone_no)} className='border-bottom py-1 hover-colored px-2 rounded-3'>
              <img src='https://svgsilh.com/svg_v2/659651.svg' className='me-2 border rounded-circle bg-white' style={{ width: "30px", height: "30px", objectFit: "cover" }} />
              {element.name}
            </li>
          ))}
        </ul>
      </>
    )}
  </div>
) : null}


    </div>
    <div className='col-3 col-sm-12 col-12 col-lg-3 col-md-3'>
    <button className='btn btn-primary w-100 fs-small py-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Add New Patient</button>
    <div className="offcanvas offcanvas-end custom-width" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header ">
                        <h5 className="offcanvas-title" id="offcanvasRightLabel">Add New Patient</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" id='close-btn' aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body border-top">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title border-bottom pb-3"><small><i className="fa-solid fa-user pe-2"></i> Personal Information</small></h5>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-6 col-md-12 '>
                                        <label className='mb-1'>Full Name</label>
                                        <input className='form-control' name='fullName' value={formData.fullName} onChange={handleChange} placeholder='Enter Full Name' type='text' />
                                        {errors.fullName && <p className="text-danger">{errors.fullName}</p>}
                                    </div>
                                    <div className='col-lg-6 col-sm-12 col-md-6 '>
                                        <label className='mb-1'>Age</label>
                                        <input className='form-control' name='age' value={formData.age} onChange={handleChange} placeholder='Enter Age' type='text' />
                                        {errors.age && <p className="text-danger">{errors.age}</p>}
                                    </div>
                                    <div className='col-lg-6 col-sm-12 col-md-6 mt-2'>
                                        <label >Age Type</label>
                                        <select value={formData.ageType} name='ageType' onChange={handleChange} className='form-select'>
                                            <option value=''>Choose</option>
                                            <option value='years'>Years</option>
                                            <option value='months'>Months</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-6 col-sm-12 col-md-6 mt-2'>
                                        <label >Gender</label>
                                        <select value={formData.gender} name='gender' onChange={handleChange} className='form-select'>
                                            <option value=''>Choose</option>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>
                                        </select>
                                        {errors.gender && <p className="text-danger">{errors.gender}</p>}
                                    </div>
                                    <div className='col-lg-6 col-sm-6 col-md-12 mt-2'>
                                        <label className='mb-1'>Phone no</label>
                                        <input name='phoneNo' value={formData.phoneNo} onChange={handleChange} className='form-control' placeholder='Enter Phone no' type='text' />
                                        {errors.phoneNo && <p className="text-danger">{errors.phoneNo}</p>}
                                    </div>
                                    <div className='col-lg-6 col-sm-6 col-md-12 mt-2'>
                                        <label className='mb-1'>CNIC/Passport</label>
                                        <input name='cnicOrPassport' value={formData.cnicOrPassport} onChange={handleChange} className='form-control' placeholder='Enter CNIC / Passport' type='text' />
                                        {errors.cnicOrPassport && <p className="text-danger">{errors.cnicOrPassport}</p>}
                                    </div>
                                    <div className='col-lg-12 col-sm-12 col-md-12 mt-2'>
                                        <label className='mb-1'>Relationship</label>
                                        <select name='relationship' value={formData.relationship} onChange={handleChange} className='form-select'>
                                            <option value=''>Choose</option>
                                            <option value='Father'>Father</option>
                                            <option value='Mother'>Mother</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-2">
                            <div className="card-body">
                                <h5 className="card-title border-bottom pb-3"><small><i className="fa-solid fa-location-dot pe-2"></i> Address</small></h5>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-6 col-md-12 '>
                                        <label className='mb-1'>City</label>
                                        <input name='city' value={formData.city} onChange={handleChange} className='form-control' placeholder='Enter City' type='text' />
                                    </div>
                                    <div className='col-lg-6 col-sm-12 col-md-6 '>
                                        <label className='mb-1'>Country</label>
                                        <input name='country' value={formData.country} onChange={handleChange} className='form-control' placeholder='Enter Country' type='text' />
                                    </div>
                                    <div className='col-lg-12 col-sm-12 col-md-12 mt-2'>
                                        <label className='mb-1'>Address</label>
                                        <input name='address' value={formData.address} onChange={handleChange} className='form-control' placeholder='Enter Address' type='text' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-2">
                            <div className="card-body">
                                <h5 className="card-title border-bottom pb-3"><small><i className="fa-solid fa-share-nodes me-2"></i> Social Account</small></h5>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-6 col-md-12 '>
                                        <label className='mb-1'>Email</label>
                                        <input name='email' value={formData.email} onChange={handleChange} className='form-control' placeholder='Enter Email' type='email' />
                                    </div>
                                    <div className='col-lg-6 col-sm-12 col-md-6 '>
                                        <label className='mb-1'>Whatsapp No</label>
                                        <input name='whatsappno' value={formData.whatsappno} onChange={handleChange} className='form-control' placeholder='Enter Whatsapp No' type='text' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button onClick={handleSubmit} className='btn btn-primary mt-3 px-5 ms-auto d-block mb-2'>Submit</button>
                        </div>
                    </div>
                </div>
                </div>
</>

}
    <div className='col-12  mt-4'>
      <div className="table-responsive border-bottom" style={{height:'220px',overflow:'auto'}}>
        <table className="mytable  table-sm">
          <thead>
            <tr>
              <th scope="col" className='fw-regular'>Test</th>
              <th scope="col">Charges</th>
              {/* <th scope="col">Discount</th> */}
              <th scope="col">Total</th>
              <th scope="col">Action</th>

            </tr>
          </thead>
          <tbody>
          
            {selectedItems.map((item ,key) => (
              <tr className="" key={key}>
          <td key={item.id}>
            {item.name} 
          </td>
         
        <td>Rs:{item.price}</td>
         {/* <td>{item.discount}</td> */}
           <td>Rs:{item.price}</td>
        <td> <i  style={{ cursor: 'pointer', marginLeft: '10px' }} 
              onClick={() => deleteItem(item.id)} className="fa-solid fa-trash hover-icon"></i></td>
        </tr>
        ))}
            
      

          
          
          
           
          </tbody>
        </table>
        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Test Discount</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <label className='my-2'>Amount In Rs</label>
        <input 
          type='number'
  // value={discount} 
  // onChange={(e) => setDiscount(e.target.value)} 
  className='form-control' 

/>

        <label className='my-2'>Reason</label>
        <select className='form-select'>
          <option>Choose</option>
        </select>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button  onClick={() => handleDiscount(discount)} type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
{/*  */}

{/* Discount Modal */}

<div className="modal fade" id="discountmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Discount</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Rs</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">%</button>
  </li>

</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">  <label className='my-2'>Amount In Rs</label>
  <input 
      type='number' 
      className='form-control' 
      value={discount} 
      onChange={(e) => setDiscount(parseFloat(e.target.value))}
    />
        <label className='my-2'>Reason</label>
        <select className='form-select'>
          <option>Choose</option>
        </select></div>
  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">  <label className='my-2'>Amount In %</label>
        <input className='form-control' value={0}/>
        <label className='my-2'>Reason</label>
        <select className='form-select'>
          <option>Choose</option>
        </select></div>

</div>
      </div>
      <div className="modal-footer">
        <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="reset" onClick={() => calculateTotals(selectedItems, discount)} className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

{/*  */}
      </div>
      <table className='mytable custom-table table-borderless table-hover'>
        <tr >
          <td>Items</td>
          <td>{total.totalItemsCount}</td>
          <td>Total</td>
          <td className='text-end'>{total.total}</td>

        </tr>
        <tr >
        <td>Order Tax</td>
          <td>0</td>
      
      <td>Discount <i className="fa-regular fa-pen-to-square" data-bs-toggle="modal" data-bs-target="#discountmodal"></i></td>
          <td className='text-end' >{total.discount}</td>
          </tr>
          <tr className='bg-primary text-light'>
        <td style={{borderRight:'1px solid white'}} colSpan={2}>Total Payment</td>
          <td  className='text-end' colSpan={2}>{total.grandTotal}</td>
      
    
          </tr>
          </table>
          
    </div>
    <div className='row'>
      <div className='col-12 d-flex flex-row flex-wrap'>
        <button className='btn btn-primary py-2 px-4' data-bs-toggle="offcanvas" data-bs-target="#paymentcanvas" aria-controls="offcanvasRight"><i className="fa-solid fa-money-check-dollar text-light" ></i> Payment</button>
           <button className='btn btn-primary py-2 mx-1'><i className="fa-regular fa-eye text-light"></i> View Bill</button>
           <button className='btn btn-primary py-2 mx-1'><i className="fa-regular fa-circle-xmark text-light"></i> Cancel</button>
           <button className='btn btn-primary py-2 mx-1'><i className="fa-solid fa-arrows-rotate text-light"></i> Reload Test</button>
      </div>
    </div>
    <div className="offcanvas offcanvas-end custom-width"  tabIndex="-1" id="paymentcanvas" aria-labelledby="offcanvasRightLabel" >
  <div className="offcanvas-header ">
    <h5 className="offcanvas-title" id="offcanvasRightLabel">Payment</h5>
    <button type="button" id='close-offcanvas' className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body border-top"  >
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <div className='p-3 border'>

       
          <label htmlFor="">Choose Payment Method</label>
        <select name="" className='form-select' id="">
          <option value="">Choose Payment</option>
          <option value="Cash">Cash</option>
          <option value="credits">Credits</option>
          <option value="easypaisa">Easypaisa</option>
          <option value="jazzcash">JazzCash</option>
        </select>
        <div className='mt-2'>
        <label>Amount</label>
        <input 
      value={total.grandTotal} 
      onChange={handleChangeVal} 
      className='form-control'  
    />
        </div>
        </div>
        <div className='p-3 border mt-3'>
          <h6 className=' border-bottom pb-2 border-1'>SALE SUMMARY</h6>
          <div className='sale-info'>
            <div className='d-flex flex-row justify-content-between pb-2'>
                <small>Discount</small>
                <small>Rs.{total.discount}</small>
            </div>
            <div className='d-flex flex-row justify-content-between pb-2'>
                <small>Subtotal</small>
                <small>Rs.{total.total}</small>
            </div>
            <div className='d-flex flex-row justify-content-between pb-2 border-bottom'>
                <small>Tax</small>
                <small>Rs.0</small>
            </div>
            <div className='d-flex flex-row justify-content-between pb-2 border-bottom pb-2 mt-2'>
                <small className='fw-bold d-block'>Payment</small>
                <small className='fw-bold d-block'>Rs.{total.grandTotal}</small>
            </div>
            <div className='d-flex flex-row justify-content-between pb-2 border-bottom pb-2 mt-2'>
                <small className='fw-bold d-block'>Remaining</small>
                <small className='fw-bold d-block'>Rs.0</small>
            </div>
            <div className='d-flex flex-column justify-content-center pb-2   pb-2 mt-2'>
                <h6 className='fw-bold d-block text-center mt-2'>Payment List</h6>
                <div className="d-flex flex-row justify-content-between">
                <small className='fw-bold d-block'>Credit</small>
                <small className='fw-bold d-block'>Rs.{total.grandTotal}</small>
                </div>
              
            </div>
          </div>
        </div>
     
        </div>
      </div>
    </div>
   
   <div>
      <button onClick={createPayment} className='btn btn-primary mt-3 px-5 ms-auto d-block mb-2'>Submit</button>
    </div>
  </div>
</div>
   </div>

          </div>
          <div className='col-md-5 col-lg-5 col-12 col-sm-12  '>
          <Departments getTestData={getTestData}  />
        </div>
        </div>
       </div>
    </div>
  )
}
