import React from 'react'
export default function Card(props) {
  return (
    <>
      <div className='shadow  py-4 px-4'>
        <div className='row'>
          <div className='col-4'> <i className={`${props.iconClass} display-5 light-blue`}></i></div>
          <div className='col-8'>
            <h6>{props.heading}</h6>
            <h5 className='text-primary'>{props.count}</h5>
          </div>
        </div>
      </div>
    </>
  )
}
