import React from 'react'
import '../assets/css/style.css';
import Avatar from '../assets/uploads/avatar.jpg';
import logo from '../assets/uploads/logo3.png';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';


export default function Navbar() {

  
  const handleLogout = () => {
    toast.success('Logout Successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    

  };

  const avatar = {
    width: '40px',
    height: "40px",
    borderRadius: '50%',
    objectFit: 'contain',
  }
  const logoStyle = {
    width: "37%",

  }
  return (
    <div><nav>
      <div className="container-fluid border-bottom w-100 py-3 px-5 shadow">
        <div className="row">
          <div className="col-lg-2"><img src={logo} style={logoStyle} /></div>
          <div className="col-lg-8">

          <ul className='list-unstyled list-inline mb-0 mt-3' style={{ fontWeight: '600' }}>
              <li className='list-inline-item px-2'><Link to="/">Dashboard</Link></li>
              <li className='list-inline-item px-2'><Link to="pos">POS</Link></li>
              <li className='list-inline-item px-2'>
                <div className="dropdown">
                  <p className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Reception
                  </p>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/patients">Patients</Link></li>
                    <li><Link className="dropdown-item" to="/diagnostics">Diagnostics Test</Link></li>
                  </ul>
                </div>
                <Link to="department"></Link></li>
              <li className='list-inline-item px-2'>
                <div className="dropdown">
                  <p className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Departments
                  </p>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="test/pending-test">Pending Tests</Link></li>
                    <li><Link className="dropdown-item" to="test/complete-test">Complete Tests</Link></li>
                    <li><Link className="dropdown-item" to="/labforms">Lab Forms</Link></li>
                    <li><Link className="dropdown-item" href="#">Optional Product</Link></li>
                    <li><Link className="dropdown-item" href="#">Additional Product</Link></li>
                    <li><Link className="dropdown-item" href="#">Reports</Link></li>
                  </ul>
                </div>
                <Link to="department"></Link></li>
           
              <li className='list-inline-item px-2'>
                <div className="dropdown">
                  <p className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Inventory
                  </p>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="#">Products</Link></li>
                    <li><Link className="dropdown-item" to="#">Purchase</Link></li>
                    <li><Link className="dropdown-item" to="/categories">Categories</Link></li>
                    <li><Link className="dropdown-item" to="#">Payment</Link></li>
                  </ul>
                </div>
                <Link to="department"></Link></li>
                <li className='list-inline-item px-2'>
                <div className="dropdown">
                  <p className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Setting
                  </p>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/roles">Roles</Link></li>
                    <li><Link className="dropdown-item" to="/permissions">Permissions</Link></li>
                    <li><Link className="dropdown-item" to="/users">Users</Link></li>
                    <li><Link className="dropdown-item" to="#">Application Setting</Link></li>
                  </ul>
                </div>
                <Link to="department"></Link></li>
            </ul>
          </div>

          <div className="col-lg-2">
            <div className="d-flex flex-row flex-wrap justify-content-end">
              <div className="dropdown">
              <>
  <a
    className="nav-link dropdown-toggle hide-arrow"
    href="#"
    data-bs-toggle="dropdown"
    data-bs-auto-close="outside"
    aria-expanded="false"
  >
    <i className="fa-solid fa-bell fa-xl" />
    <span className="badge bg-danger rounded-pill badge-notifications">5</span>
  </a>
  <ul className="dropdown-menu dropdown-menu-end py-0 shadow border-0 noti-dropdown" >
    <li className="dropdown-menu-header border-bottom">
      <div className="dropdown-header d-flex align-items-center py-3">
        <h5 className="text-body mb-0 me-auto">Notification</h5>
        <a
          href="#"
          className="dropdown-notifications-all text-body"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Mark all as read">
          <i className="ti ti-mail-opened fs-4" />
        </a>
      </div>
    </li>
    <li className="dropdown-notifications-list scrollable-container">
      <ul className="list-group list-group-flush">
        <li className="list-group-item list-group-item-action dropdown-notifications-item">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <img
                  src={Avatar}
                  alt=""
                  className="h-auto rounded-circle"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">Congratulation Lettie 🎉</h6>
              <p className="mb-0">Won the monthly best seller gold badge</p>
              <small className="text-muted">1h ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive" >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <span className="avatar-initial rounded-circle bg-label-danger">
                  CF
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">Charles Franklin</h6>
              <p className="mb-0">Accepted your connection</p>
              <small className="text-muted">12hr ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <img
                  src={Avatar}
                  alt=""
                  className="h-auto rounded-circle"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">New Message ✉️</h6>
              <p className="mb-0">You have new message from Natalie</p>
              <small className="text-muted">1h ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <span className="avatar-initial rounded-circle bg-label-success">
                  <i className="ti ti-shopping-cart" />
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">Whoo! You have new order 🛒</h6>
              <p className="mb-0">ACME Inc. made new order $1,154</p>
              <small className="text-muted">1 day ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <img
                  src={Avatar}
                  alt=""
                  className="h-auto rounded-circle"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">Application has been approved 🚀</h6>
              <p className="mb-0">
                Your ABC project application has been approved.
              </p>
              <small className="text-muted">2 days ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <span className="avatar-initial rounded-circle bg-label-success">
                  <i className="ti ti-chart-pie" />
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">Monthly report is generated</h6>
              <p className="mb-0">July monthly financial report is generated</p>
              <small className="text-muted">3 days ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <img
                  src={Avatar}
                  alt=""
                  className="h-auto rounded-circle"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">Send connection request</h6>
              <p className="mb-0">Peter sent you connection request</p>
              <small className="text-muted">4 days ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <img
                  src={Avatar}
                  alt=""
                  className="h-auto rounded-circle"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">New message from Jane</h6>
              <p className="mb-0">Your have new message from Jane</p>
              <small className="text-muted">5 days ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <div className="avatar">
                <span className="avatar-initial rounded-circle bg-label-warning">
                  <i className="ti ti-alert-triangle" />
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <h6 className="mb-1">CPU is running high</h6>
              <p className="mb-0">
                CPU Utilization Percent is currently at 88.63%,
              </p>
              <small className="text-muted">5 days ago</small>
            </div>
            <div className="flex-shrink-0 dropdown-notifications-actions">
              <a
                href="#"
                className="dropdown-notifications-read"
              >
                <span className="badge badge-dot" />
              </a>
              <a
                href="#"
                className="dropdown-notifications-archive"
              >
                <span className="ti ti-x" />
              </a>
            </div>
          </div>
        </li>
      </ul>
    </li>
    <li className="dropdown-menu-footer border-top">
      <a
        href="#"
        className="dropdown-item d-flex justify-content-center text-primary p-2 h-px-40 mb-1 align-items-center"
      >
        View all notifications
      </a>
    </li>
  </ul>
</>

              </div>

              <div className="dropdown">
                <img src={Avatar} alt='avatar' style={avatar} className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul className="dropdown-menu dropdown-menu-light">
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to="user-profile" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Profile
                    </Link>

                  </li>
                  <li><Link className="dropdown-item" onClick={handleLogout}>Logout</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
     
    </nav></div>
  )
}
