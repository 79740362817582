import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Accounts from './pages/Accounts';
import Department from './pages/Department';
import Dashboard from './pages/Dashboard';
import Pos from './pages/pos/Index';
import Sales from './pages/Sales';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import CompleteTest from './pages/Tests/Complete';
import PendingTest from './pages/Tests/Pending';
import MyProfile from '../src/pages/profile/index';
import Patients from '../src/pages/patients/Index';
import Diagnostics from '../src/pages/diagnostics/Index';
import Category from '../src/pages/category/Index';
import Labforms from '../src/pages/labforms/Index';
import Roles from './pages/auth/roles/Index';
import Permissions from './pages/auth/permissions/Index';
import Users from './pages/auth/users/Index';
import Login from './pages/auth/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
 
  const location = useLocation();
  const token = localStorage.getItem('token');
  const isLoginPage = location.pathname === '/login';


  return (
    <>
      {!isLoginPage && <Navbar />}
      <Routes>
     
        <Route
          path="/pos"
          element={token ? <Pos /> : <Navigate to="/login" />}
        />
        <Route
          path="/accounts"
          element={token ? <Accounts /> : <Navigate to="/login" />}
        />
        <Route
          path="/department"
          element={token ? <Department /> : <Navigate to="/login" />}
        />
        <Route
          path="/test/complete-test"
          element={token ? <CompleteTest /> : <Navigate to="/login" />}
        />
        <Route
          path="/test/pending-test"
          element={token ? <PendingTest /> : <Navigate to="/login" />}
        />
        <Route
          path="/sales"
          element={token ? <Sales /> : <Navigate to="/login" />}
        />
        <Route
          path="/user-profile"
          element={token ? <MyProfile /> : <Navigate to="/login" />}
        />
        <Route
          path="/"
          element={token ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/patients"
          element={token ? <Patients /> : <Navigate to="/login" />}
        />
        <Route
          path="/diagnostics"
          element={token ? <Diagnostics /> : <Navigate to="/login" />}
        />
        <Route
          path="/categories"
          element={token ? <Category /> : <Navigate to="/login" />}
        />
        <Route
          path="/labforms"
          element={token ? <Labforms /> : <Navigate to="/login" />}
        />
        <Route
          path="/roles"
          element={token ? <Roles /> : <Navigate to="/login" />}
        />
        <Route
          path="/permissions"
          element={token ? <Permissions /> : <Navigate to="/login" />}
        />
        <Route
          path="/users"
          element={token ? <Users /> : <Navigate to="/login" />}
        />
        {/* Public Routes */}
        <Route
          path="/pos"
          element={token ? <Pos /> : <Navigate to="/login" />}
        />
        {/* Add more protected routes with similar logic */}
        <Route
          path="/login"
          element={token ? <Navigate to="/dashboard" /> : <Login />}
        />
        
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
