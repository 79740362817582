import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import Table from '../../../components/Table';
import { USER_API, ROLES_API, STORE_USER,DELETE_USER ,EDIT_USER,UPDATE_USER} from '../../../routes/api/Index';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const token = localStorage.getItem('token');
  const [roles, setRoles] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [roleData, setRoleData] = useState('');
  const [userid,setUserID]=useState('');


  function resetValues(){
    setPassword('');
    setConfirmPassword('');
    setName('');
    setEmail('');
    setRoleData('');
    setUserID('');
  }

  function assignvalues(name,email,role){
  
    setName(name);
    setEmail(email);
    setRoleData(role);
  }

  const handleDelete=async(id)=>{
    const response = await fetch(`${DELETE_USER}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if(responseData.status===200){
      toast.success(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      fetchData();
    } 
    else{
      toast.error(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleEdit=async(id)=>{
   
    const response = await fetch(`${EDIT_USER}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    let user_data=responseData.data;
    assignvalues(user_data.name,user_data.email,user_data.roles[0].name);
    if(responseData.status===200){
      setUserID(id);
      toast.success(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    
    } 
    else{
      toast.error(responseData.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    
    
  }

  const handleUpdate = async (userid) => {
    try {

      if(confirmPassword !== password){
        toast.error("Confirm password doesn't matches password", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return 0;
      }
      const queryParams = new URLSearchParams({
        userid,
        name,
        email,
        password,
        confirmPassword,
        roleData,
      }).toString();
  
      const response = await fetch(`${UPDATE_USER}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const responseData = await response.json();
      if (!response.ok) {
        const errorMessage = responseData.message || 'Network response was not ok';
        throw new Error(errorMessage);
      }
      if(responseData.status === 200){

      
      toast.success(responseData.message || 'Form submitted successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      fetchData();
      const offcanvasAddUser = document.getElementById('update-offcanvas');
      offcanvasAddUser.click();
      resetValues();
    }
    else{
      toast.error(`${responseData.message}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  
     
  
      console.log('Form submitted successfully');
    } catch (error) {
      toast.error(`${error}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleSubmit = async () => {
    try {
        if (confirmPassword !== password) {
            toast.error("Confirm password doesn't match password", {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }

        const requestBody = JSON.stringify({
            name,
            email,
            password,
            confirmPassword,
            roleData,
        });

        const response = await fetch(STORE_USER, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
        });

        const responseData = await response.json();

        if (!response.ok) {
            const errorMessage = responseData.message || 'Network response was not ok';
            throw new Error(errorMessage);
        }

        if (responseData.status === 200) {
            toast.success(responseData.message || 'Form submitted successfully', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });

            fetchData();

            const offcanvasAddUser = document.getElementById('close-offcanvas');
            offcanvasAddUser.click();
            resetValues();
        } else {
            toast.error(`${responseData.message}`, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }

        console.log('Form submitted successfully');
    } catch (error) {
        toast.error(`${error}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
        console.error('Error submitting form:', error);
    }
};


  
  

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    setPageIndex(selectedPage);
  };

  useEffect(() => {
    fetchData();
    fetchRoles();
  }, [pageIndex]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${USER_API}?page=${pageIndex}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const responseData = await response.json();
      setData(responseData.data);
      setPageCount(responseData.last_page);
      console.log(responseData.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${ROLES_API}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const responseData = await response.json();
      setRoles(responseData.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: 's.no',
        Cell: ({ row }) => row.index + 1 + pageIndex * 10,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'roles',
        Cell: ({ value }) => {
          // Assuming roles is an array with one element
          if (Array.isArray(value) && value.length === 1) {
            return value[0].name || 'No Role';
          } else {
            return 'No Role'; // Handle case where there are no roles or multiple roles
          }
        }
      },
      {
        Header: <div className="text-center">Actions</div>,
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className="d-flex flex-row justify-content-center">
            <i onClick={() => handleEdit(row.original.id)} aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#updateuser" className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
            <i onClick={() => handleDelete(row.original.id)} className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"></i>
          </div>
        ),
      },
    ],
    [pageIndex]
  );

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <BreadCrumb parent="Users" current="All" />
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <button id='add-user-click' className="btn add-new btn-primary mb-3 mb-md-0 float-end" tabIndex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAddUser"><span>Add User</span></button>
          </div>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
            <div className="offcanvas-header">
              <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Add User</h5>
              <button type="button" className="btn-close text-reset" id='close-offcanvas' data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
              <form action="#" method="POST">
                <div className="mb-3">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" required value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" required value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="password">Password</label>
                  <input type="password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} placeholder="***********" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <input type="password" name="confirm_password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="***********" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="roles">Roles</label>
                  <select required name="roles" value={roleData} onChange={(e) => setRoleData(e.target.value)} className="form-control">
                    <option value="">Select Role</option>
                    {roles.map((element) => (
                      <option key={element.name} value={element.name}>{element.name}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <button type="button" onClick={handleSubmit} className="btn btn-primary px-5">Save</button>
                </div>
              </form>
            </div>
          </div>
          {/* update user */}
          <div className="col-12 d-none">
            <button id='update-user-click' className="btn add-new btn-primary mb-3 mb-md-0 float-end" tabIndex="0" ><span>update User</span></button>
          </div>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="updateuser" aria-labelledby="offcanvasAddUserLabel">
            <div className="offcanvas-header">
              <h5 id="updateuserLabel" className="offcanvas-title">Update User</h5>
              <button type="button" className="btn-close text-reset" id='update-offcanvas' data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
              <form action="#" method="POST">
                <div className="mb-3">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" required value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" required value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="password">Password</label>
                  <input type="password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} placeholder="***********" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <input type="password" name="confirm_password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="***********" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="roles">Roles</label>
                  <select required name="roles" value={roleData} onChange={(e) => setRoleData(e.target.value)} className="form-control">
                    <option value="">Select Role</option>
                    {roles.map((element) => (
                      <option key={element.name} value={element.name}>{element.name}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <button type="button" onClick={() => handleUpdate(userid)}  className="btn btn-primary px-5">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="mb-3 text-center">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <Table columns={columns} data={data} />
              
            </>
          )}
          <div className='d-block mt-3'>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
