// apiRoutes.js

//Auth Api Route
export const LOGIN_API = 'https://testing.shellstechnologies.com/api/login';

// User Api Routes

export const USER_API = 'https://testing.shellstechnologies.com/api/user/all';

export const STORE_USER = 'https://testing.shellstechnologies.com/api/user/store';
export const DELETE_USER = 'https://testing.shellstechnologies.com/api/user/delete';
export const EDIT_USER = 'https://testing.shellstechnologies.com/api/user/edit';
export const UPDATE_USER = 'https://testing.shellstechnologies.com/api/user/update';


// Role Api Routes

export const ROLES_API = 'https://testing.shellstechnologies.com/api/role/all';
export const CREATE_ROLE = 'https://testing.shellstechnologies.com/api/role/create';
export const EDIT_ROLE = 'https://testing.shellstechnologies.com/api/role/edit';
export const UPDATE_ROLE = 'https://testing.shellstechnologies.com/api/role/update';
export const DELETE_ROLE = 'https://testing.shellstechnologies.com/api/role/delete';

// Permissions Api Routes

export const PERMISSION_API = 'https://testing.shellstechnologies.com/api/permission/all';
export const CREATE_PERMISSION = 'https://testing.shellstechnologies.com/api/permission/create';
export const EDIT_PERMISSION = 'https://testing.shellstechnologies.com/api/permission/edit';
export const UPDATE_PERMISSION = 'https://testing.shellstechnologies.com/api/permission/update';
export const DELETE_PERMISSION = 'https://testing.shellstechnologies.com/api/permission/delete';


// Categories Api Routes

export const CATEGORY_API ="https://testing.shellstechnologies.com/api/category/all-categories";
export const SUBCATEGORY_API ="https://testing.shellstechnologies.com/api/category/all-subcategories";
export const CREATE_CATEGORY ="https://testing.shellstechnologies.com/api/category/create";
export const EDIT_CATEGORY ="https://testing.shellstechnologies.com/api/category/edit-category";
export const EDIT_SUBCATEGORY ="https://testing.shellstechnologies.com/api/category/edit-subcategory";
export const UPDATE_PARENT_CATEGORY ="https://testing.shellstechnologies.com/api/category/update-category";
export const UPDATE_SUBCATEGORY ="https://testing.shellstechnologies.com/api/category/update-subcategory";
export const DELETE_PARENTCATEGORY ="https://testing.shellstechnologies.com/api/category/delete-category";
export const DELETE_SUBCATEGORY ="https://testing.shellstechnologies.com/api/category/delete-subcategory";

export const PARENT_CATEGORIES ="https://testing.shellstechnologies.com/api/category/parent-all";
export const FIND_SUBCATEGORY ="https://testing.shellstechnologies.com/api/category/find-subcategory";


// Patietn Api Routes

export const PATIENT_API = 'https://testing.shellstechnologies.com/api/patient/all';
export const CREATE_PATIENT = 'https://testing.shellstechnologies.com/api/patient/create';
export const EDIT_PATIENT = 'https://testing.shellstechnologies.com/api/patient/edit';
export const UPDATE_PATIENT = 'https://testing.shellstechnologies.com/api/patient/update';
export const DELETE_PATIENT = 'https://testing.shellstechnologies.com/api/patient/delete';
export const SEARCH_PATIENT = 'https://testing.shellstechnologies.com/api/patient/search';






