import React, { useEffect, useState } from 'react';
import FilterCategory from '../../components/FilterCategory';
import BreadCrumb from '../../components/BreadCrumb';
import {CATEGORY_API,SUBCATEGORY_API,UPDATE_PARENT_CATEGORY,UPDATE_SUBCATEGORY,CREATE_CATEGORY,DELETE_PARENTCATEGORY,DELETE_SUBCATEGORY,EDIT_CATEGORY,EDIT_SUBCATEGORY} from '../../routes/api/Index';
import ReactPaginate from 'react-paginate';
import Table from '../../components/Table';
import { toast } from 'react-toastify';
import {OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Index() {

    const token = localStorage.getItem('token');
    const [categoryName, setCategoryName] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [parentId,setParentId]=useState('');
    const [id,setId]=useState('');
    const [checkChild, setCheckChild] = useState(false);
    const [data,setData]=useState([]);
    const [subCategory,setSubCategory]=useState([]);
    const [price,SetPrice] = useState(0);
    const [error,setError]=useState("");


    function resetValues() {
        setCategoryName('');
        setParentId('')
        setCheckChild(false);
        setError('');
    }

    useEffect(() => {
        fetchData();
        fetchSubCategory();
      }, [pageIndex]);
    
  
    function assignvalues(name){
        setCategoryName(name);
       
      }

    const combinedHandler = (id) => {
        handleId(id);
        setCheckChild(true);
    };
    
      
    
      const handlePageClick = (event) => {
        const selectedPage = event.selected + 1
        setPageIndex(selectedPage);
       
      };
    
    
      const handleSubmit = async () => {
        try {
            if (!categoryName) {
             
                setError("Please Enter Name");
                return;
              }
        
          const requestBody = JSON.stringify({
            categoryName,
            parentId,
            price
          });
          
          
          const response = await fetch(CREATE_CATEGORY, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
          });
    
          const responseData = await response.json();
    
          if (!response.ok) {
            const errorMessage = responseData.message || 'Network response was not ok';
            throw new Error(errorMessage);
          }
    
          if (responseData.status === 200) {
            toast.success(responseData.message || 'Form submitted successfully', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
    
            fetchData();
            fetchSubCategory();
            resetValues();
          
            let close=document.getElementById('close-btn');
            close.click();
          } else {
            toast.error(`${responseData.message}`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
    
          console.log('Form submitted successfully');
        } catch (error) {
          toast.error(`${error}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          console.error('Error submitting form:', error);
        }
      };
    
      const handleEdit=async(id)=>{
       
        const response = await fetch(`${EDIT_CATEGORY}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
    
        const responseData = await response.json();
        let categoryName=responseData.data;
        assignvalues(categoryName.name);
        if(responseData.status===200){
          setId(id);
          
        
        } 
        else{
          toast.error(responseData.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
        
        
      }

      const subcategoryEdit=async(id)=>{
       
        const response = await fetch(`${EDIT_SUBCATEGORY}/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
    
        const responseData = await response.json();
        let categoryName=responseData.data;
        assignvalues(categoryName.name);
        if(responseData.status===200){
          setId(id);
          
        
        } 
        else{
          toast.error(responseData.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
        
        
      }
    
      const SubcategoryUpdate = async () => {
        try {
 
          const requestBody = JSON.stringify({
            id,
            categoryName,
          });
          
          const response = await fetch(UPDATE_SUBCATEGORY, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
          });
    
          const responseData = await response.json();
    
          if (!response.ok) {
            const errorMessage = responseData.message || 'Network response was not ok';
            throw new Error(errorMessage);
          }
    
          if (responseData.status === 200) {
            toast.success(responseData.message || 'Form submitted successfully', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
    
            fetchData();
           
            resetValues();
           
            let close=document.getElementById('close-update-btn');
            close.click();
          } else {
            toast.error(`${responseData.message}`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
    
          console.log('Form submitted successfully');
        } catch (error) {
          toast.error(`${error}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          console.error('Error submitting form:', error);
        }
      };
    
      const handleUpdate = async () => {
        try {
 
          const requestBody = JSON.stringify({
            id,
            categoryName,
          });
          
          const response = await fetch(UPDATE_PARENT_CATEGORY, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
          });
    
          const responseData = await response.json();
    
          if (!response.ok) {
            const errorMessage = responseData.message || 'Network response was not ok';
            throw new Error(errorMessage);
          }
    
          if (responseData.status === 200) {
            toast.success(responseData.message || 'Form submitted successfully', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
    
            fetchData();
           
            resetValues();
           
            let close=document.getElementById('close-update-btn');
            close.click();
          } else {
            toast.error(`${responseData.message}`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
    
          console.log('Form submitted successfully');
        } catch (error) {
          toast.error(`${error}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          console.error('Error submitting form:', error);
        }
      };


    
    
      const handleDelete = async () => {
        // Perform delete operation using the id
        try {
            
            const url = checkChild ? `${DELETE_SUBCATEGORY}/${id}` : `${DELETE_PARENTCATEGORY}/${id}`;
            const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
      
          const responseData = await response.json();
      
          if (!response.ok) {
            throw new Error(responseData.message || 'Network response was not ok');
          }
      
          // Handle successful deletion
          console.log('Item deleted successfully');
          toast.success(responseData.message || 'Item deleted successfully', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
    
          fetchData();
          fetchSubCategory();
          resetValues();
        } catch (error) {
          toast.error(`${error}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      }
      
      function handleId(id){
        setId(id);
      }
      
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(`${CATEGORY_API}?page=${pageIndex}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const responseData = await response.json();
        console.log(responseData.data.data);
        setData(responseData.data.data);
        setPageCount(responseData.data.last_page);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    
        }
      
      const fetchSubCategory = async () => {
            try {
              setLoading(true);
              const response = await fetch(`${SUBCATEGORY_API}?page=${pageIndex}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            });
            const responseData = await response.json();
            console.log(responseData.data);
            setSubCategory(responseData.data.data);
            setPageCount(responseData.data.last_page);
          } catch (error) {
            console.error('Error:', error);
          } finally {
            setLoading(false);
          }
        
       }

       const columns = React.useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: 's.no',
                Cell: ({ row }) => row.index + 1,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Created By',
                accessor: 'user.name',
            },
            {
                Header: <div className="text-center">Actions</div>,
                accessor: 'actions', // Unique identifier for the action column
                Cell: ({ row }) => (
                    <div className="d-flex justify-content-center">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-3">Update Category</Tooltip>}
                        >
                            <i onClick={() => handleEdit(row.original.id)} data-bs-toggle="modal" data-bs-target="#updatemodal" className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">Delete Category</Tooltip>}
                        >
                            <i
                                onClick={() => handleId(row.original.id)}
                                data-bs-target="#delete_model"
                                data-bs-toggle="modal"
                                className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"
                            ></i>
                        </OverlayTrigger>
                    </div>
                ),
            },
        ],
        [handleEdit] // Include handleEdit in the dependency array
    );
    
      const columns_subcategory = React.useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: 's.no',
                Cell: ({ row }) => row.index + 1,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Parent Name',
                accessor: 'parent_category.name',
            },
            {
                Header: 'Price',
                accessor: 'price',
            },
        
            {
                Header: 'Created By',
                accessor: 'user.name',
            },
            {
                Header: <div className="text-center">Actions</div>,
                accessor: 'actions', // Unique identifier for the action column
                Cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                         <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-4">Update Sub Category</Tooltip>}
                                >
                                   <i className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
                                </OverlayTrigger>
                               
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-5">Delete Sub Category</Tooltip>}
                                >
                                     <i 
      onClick={() => combinedHandler(row.original.id)}
      data-bs-target="#delete_model"
      data-bs-toggle="modal"
      className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"
    ></i>
                                </OverlayTrigger>
                    </div>
                ),
            },
        ],
        [handleEdit]
    );

    return (
        <div className='container-fluid p-5'>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <BreadCrumb parent="Category" current="All" />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <FilterCategory />
            </div>
            <div className='card shadow p-4 border-0 rounded-3'>
                <div className='col-12'>
                    <button className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fas fa-plus" ></i> Add Category</button>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Add Category</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" id='close-btn' aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form id="form" className="m-form m-form--state" action="/web/category/create" method="post" autoComplete="off">
                                        <input type="hidden" name="_csrf" value="leuAyehek5732OGG1S9Vt97v-Nd0HBE2ypHMjysxUnWi0tLxrW3WyZ2hh9PkYw_O6ZvL4xgpJ0az3bq7EgMEIA==" />
                                        <div className="form-group field-category-name required">
                                            <label className="form-control-label " htmlFor="category-name">Name</label>
                                            <input type="text" id="category-name" className="form-control" name="Category" value={categoryName} onChange={(e)=>setCategoryName(e.target.value)}  aria-required="true" />
                                            <p className="text-danger">{error}</p>
                                        </div>
                                        <div className="form-group field-category-tree">
                                            <label className="form-control-label " htmlFor="category-tree">Parent Category</label>
                                            <select id="category-tree" onChange={(e)=>setParentId(e.target.value)} value={parentId} className="form-control" name="Category">
                                            <option value="">Choose...</option>
                                            {data.map((element) => (
                                                <option key={element.id} value={element.id}>{element.name}</option>
                                                ))}
                                            </select>
                                            <p className="help-block help-block-error"></p>
                                        </div>
                                        {parentId && (
        <div className="form-group field-category-tree">
          <label className="form-control-label" htmlFor="category-price">Price</label>
          
          <input
            id="category-price"
            placeholder="Enter Price"
            className="form-control"
            type="number"
            value={price}
            onChange={(e) => SetPrice(e.target.value)}
          />
          <p className="help-block help-block-error"></p>
        </div>
      )}
                                       
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-pills mb-3 mt-3 " id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Categories</button>
  </li>
  <li class="nav-item mx-3" role="presentation">
    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Sub Categories</button>
  </li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
  <div className=" border-0 p-3">
                <div className="mb-3 text-center">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <Table columns={columns} data={data} />
            </>
          )}
          <div className='d-block mt-3'>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
                
                </div>
               


            </div>

  </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">   <div className=" border-0 p-3">
                <div className="mb-3 text-center">
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <Table columns={columns_subcategory} data={subCategory} />
            </>
          )}
          <div className='d-block mt-3'>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
                
                </div>
               


            </div>
</div>
  
</div>
             

           </div>
         {/* DELETE Modal */}
      <div className='modal fade' id={`delete_model`} tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" >
      <div className="modal-dialog modal-dialog-centered modal-lg modal-add-new-role" style={{width:"480px"}}>
        <div className="modal-content p-3 p-md-5">
          <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body">
            <div className="text-center mb-4">
              <h3 className="role-title mb-2">Confirm Delete</h3>
              <p className="text-muted">Are you sure you want to delete this item?</p>
            </div>
            <div className="col-12 mb-4">
              <input type="hidden" name="id" value={id} className="form-control" />
            </div>
            <div className="col-12 text-center mt-4">
            <button data-bs-dismiss="modal" aria-label="Close" onClick={handleDelete}   className="btn btn-danger me-2">
            <span><small>Confirm Delete</small></span></button>
              <button type="reset" className="btn btn-label-secondary waves-effect"  data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            </div>
          </div>
        </div>
      </div>
              </div>
              
          {/* Edit Modal */}

          <div className="modal fade" id="updatemodal" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-3 p-md-5">
                                <button
                                    type="button"
                                    id='close-update-btn'
                                    className="btn-close btn-pinned"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                <div className="modal-body">
                                    <div className="text-center mb-4">
                                        <h3 className="mb-2">Update Parent Category</h3>
                                        <p className="text-muted">Its Parent Diagnostic Test Name.</p>
                                    </div>
                                   
                                    <div className="col-12 mb-3">
      <label className="form-label" htmlFor="modalPermissionName">Name</label>
      <input 
        type="text" 
        className='form-control' 
        name='name' 
       // Added id to match the label's htmlFor attribute
        value={categoryName} 
        onChange={(e) => setCategoryName(e.target.value)} // Ensure setCategoryName is used
      />
    </div>
                                        

                                      
                                        <div className="col-12 mb-2">

                                        </div>
                                        <div className="col-12 text-center demo-vertical-spacing">
                                            <button onClick={handleUpdate} type="button" className="btn btn-primary me-sm-3 me-1">Update</button>
                                            <button
                                            
                                                type="reset"
                                                className="btn btn-label-secondary"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                Discard
                                            </button>
                                        </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>

          {/*  */}
            </div>


    );
} 
