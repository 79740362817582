import React from 'react';
import { useTable, usePagination } from 'react-table';
import fakedata from '../../fakedata/diagnostics.json';
import FilterDiagnostics from '../../components/FilterDiagnostics';
import BreadCrumb from '../../components/BreadCrumb';

export default function Index() {
  const data = React.useMemo(() => fakedata, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: 's.no',
        Cell: ({ row }) => row.index + 1,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Consultant',
        accessor: 'consultant',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created On',
        accessor: 'created_on',
      },
      {
        Header: 'Created By',
        accessor: 'created_by',
      },
      {
        Header: 'Action',
        Cell: () => (
          <div className="d-flex flex-row justify-content-center">
            <i className="fa-solid fa-eye bg-info text-white rounded-circle p-2 me-1 hover"></i>
            <i className="fa-regular fa-pen-to-square bg-warning text-white rounded-circle p-2 me-1 hover"></i>
            <i className="fa-solid fa-trash-can bg-danger text-white rounded-circle p-2 me-1 hover"></i>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className='container-fluid p-5'>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <BreadCrumb parent="Diagnostics" current="All" />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <FilterDiagnostics />
      </div>
      <div className='card shadow p-4 border-0 rounded-3'>
        <div className='col-12'>
          <button className="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fas fa-plus" ></i> Add Diagnostics Test</button>
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Add Diagnostics Test</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="item-name-create">




<div className="item-name-form">

<form id="form" className="m-form m-form--state" action="/web/item-name/create" method="post" autoComplete="off">
<input type="hidden" name="_csrf" value="9386ejkieFgz2U4roqmgL_20vFPfumrw10pr3Sk9Y7G_DAwcfng8EGKVK2Xbn8RIp-P0FqXvHrOjIiKIeVQq9g==" />
<div className="form-group field-itemname-cat_id required">
<label className="form-control-label " for="itemname-cat_id">Category</label>
<select id="itemname-cat_id" className="form-control" name="ItemName[cat_id]" aria-required="true">
<option value="">Select Category</option>
<option value="4">MRI</option>
<option value="5">CT Scan</option>
<option value="6">XRay</option>
<option value="7">Lab</option>
<option value="8">Ultra Sound</option>
<option value="9">Echo</option>
<option value="10">Brig Ashraf Farooq (U/S)</option>
<option value="11">Extra</option>
<option value="12">OPG</option>
<option value="13">EEG</option>
<option value="14">Brig Junaid Mushtaq Lab</option>
</select>
<p className="help-block help-block-error"></p>
</div>
<div className="form-group field-itemname-name required">
<label className="form-control-label " for="itemname-name">Name</label>
<input type="text" id="itemname-name" className="form-control" name="ItemName[name]" maxLength="50" aria-required="true" />
<p className="help-block help-block-error"></p>
</div>
<div className="form-group field-itemname-price required">
<label className="form-control-label " for="itemname-price">Price</label>
<input type="text" id="itemname-price" className="form-control" name="ItemName[price]" aria-required="true" />
<p className="help-block help-block-error"></p>
</div>
<div className="form-group field-itemname-consultant_percentage">
<label className="form-control-label " for="itemname-consultant_percentage">Consultant %</label>
<input type="text" id="itemname-consultant_percentage" className="form-control" name="ItemName[consultant_percentage]" />
<p className="help-block help-block-error"></p>
</div>
<div className="form-group field-itemname-status required">
<label className="form-control-label " for="itemname-status">Status</label>
<select id="itemname-status" className="form-control" name="ItemName[status]" aria-required="true">
<option value="1">Active</option>
<option value="0">InActive</option>
</select>
<p className="help-block help-block-error"></p>
</div>    

</form>
</div>

</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Submit</button>
                    </div>
                </div>
  </div>
              </div>
        </div>
        <table {...getTableProps()} className="table table-bordered">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <button className='btn btn-primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>{' '}
          <button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>{' '}
          <span>
            Page{' '}
            {pageOptions.map((page, index) => (
              <button className='btn btn-primary me-2' key={index} onClick={() => gotoPage(index)} disabled={pageIndex === index}>
                {index + 1}
              </button>
            ))}
          </span>
        </div>
      </div>

    </div>

  );
} 
